import React from "react";
import Home from "../Components/Home";
import AppLayout from "../Layouts/AppLayout";

const Dashboard = () => {
    return (
        <AppLayout>
            <>
                <Home/>
            </>
        </AppLayout>
    );
};

export default Dashboard;
