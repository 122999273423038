import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Students {
  loading: boolean;
  data: any;
  singleData: any;
  singleLoading: boolean;
  resetData: any;
  resetLoading: boolean;
  editData: any;
  editLoading: boolean;
}

export interface SearchMatricData {
  matricNumber: string;
}
export interface SearchIntf {
  name: string;
}
export interface EditNameData {
  lastName: string;
  firstName: string;
  middleName: string;
  id: string;
}

// export interface ResetData {
//   matricNumber: string;
// }

const initialState: Students = {
  loading: false,
  data: null,
  singleData: null,
  singleLoading: false,
  resetData: null,
  resetLoading: false,
  editData: null,
  editLoading: false,
};

export const searchByMatric = createAsyncThunk(
  "searchMatric",
  async (data: SearchMatricData) => {
    return axiosInstance
      .post("/students/search-by-matric", data)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const searchByName = createAsyncThunk(
  "searchName",
  async (data: SearchIntf) => {
    return axiosInstance
      .post("/students/search-by-name", data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const getStudById = createAsyncThunk(
  "getStudById",
  async (id: number) => {
    return axiosInstance
      .get(`/students/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const resetStudentPassword = createAsyncThunk(
  "resetStudentPassword",
  async (matricNumber: string) => {
    return axiosInstance
      .post("/students/reset-password", { matricNumber })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const editStudentName = createAsyncThunk(
  "editStudentName",
  async (data: EditNameData) => {
    return axiosInstance
      .put(`/students/${data.id}`, data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const studentSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    clearStudentData: (state) => {
      state.loading = false;
      state.data = null;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchByMatric.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(searchByMatric.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(searchByMatric.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(searchByName.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(searchByName.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(searchByName.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(getStudById.pending, (state) => {
      state.loading = false;
      // state.data = null;
      state.singleData = null;
      state.singleLoading = true;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(getStudById.fulfilled, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      state.singleData = payload;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(getStudById.rejected, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      state.singleData = payload;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(resetStudentPassword.pending, (state) => {
      state.loading = false;
      //   state.data = null;
      // state.singleData = payload;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = true;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(resetStudentPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      // state.singleData = payload;
      state.singleLoading = false;
      state.resetData = payload;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(resetStudentPassword.rejected, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      // state.singleData = payload;
      state.singleLoading = false;
      state.resetData = payload;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(editStudentName.pending, (state) => {
      state.loading = false;
      state.singleLoading = true;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = true;
    });
    builder.addCase(editStudentName.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = payload;
      state.editLoading = false;
    });
    builder.addCase(editStudentName.rejected, (state, { payload }) => {
      state.loading = false;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = payload;
      state.editLoading = false;
    });
  },
});

export const { clearStudentData } = studentSlice.actions;

export default studentSlice.reducer;
