import React from "react";

const BtnLoader = () => {
  return (
    <button
      type="submit"
      className={
        "link fw-bold btn px-5 py-3 mb-3 border-0 w-100 position-relative"
      }
      disabled
    >
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </button>
  );
};

export default BtnLoader;
