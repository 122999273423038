import React from "react";
import NewUsr from "../Components/utility/newUsr";
import AppLayout from "../Layouts/AppLayout";

const NewUser = () => {
  return (
    <AppLayout>
      <>
        <NewUsr />
      </>
    </AppLayout>
  );
};

export default NewUser;
