import React from "react";

const Home: React.FC = () => {
  const styles = {
    progress: {
      width: "75%",
      role: "progressbar",
      ariaValuenow: "75",
      ariaValuemin: "0",
      ariaValuemax: "100",
      ariaLabel: "75% Complete",
    },
  };
  return (
    <>
      <div>
        <div className="page-wrapper">
          {/* Page header */}
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="page-header d-print-none">
                <div className="col">
                  <h2 className="page-title">Welcome Admin</h2>
                </div>
              </div>
            </div>
          </div>
          {/* Page body */}
          <div className="page-body">
            <div className="container-xl">
              <div className="row row-deck row-cards">
                <div className="col-sm-6 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="subheader">Sales</div>
                        <div className="ms-auto lh-1">
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle text-muted"
                              href="/"
                              data-bs-toggle="dropdown"
                              // aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Last 7 days
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item active" href="/">
                                Last 7 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 30 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 3 months
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h1 mb-3">75%</div>
                      <div className="d-flex mb-2">
                        <div>Conversion rate</div>
                        <div className="ms-auto">
                          <span className="text-green d-inline-flex align-items-center lh-1">
                            7% Download SVG icon from
                            http://tabler-icons.io/i/trending-up
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon ms-1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <polyline points="3 17 9 11 13 15 21 7"></polyline>
                              <polyline points="14 7 21 7 21 14"></polyline>
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div className="progress progress-sm">
                        <div
                          className="progress-bar bg-primary"
                          style={styles.progress}
                        >
                          <span className="visually-hidden">75% Complete</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="subheader">Revenue</div>
                        <div className="ms-auto lh-1">
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle text-muted"
                              href="/"
                              data-bs-toggle="dropdown"
                              //   aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Last 7 days
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item active" href="/">
                                Last 7 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 30 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 3 months
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <div className="h1 mb-0 me-2">$4,300</div>
                        <div className="me-auto">
                          <span className="text-green d-inline-flex align-items-center lh-1">
                            8% Download SVG icon from
                            http://tabler-icons.io/i/trending-up
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon ms-1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <polyline points="3 17 9 11 13 15 21 7"></polyline>
                              <polyline points="14 7 21 7 21 14"></polyline>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="subheader">New clients</div>
                        <div className="ms-auto lh-1">
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle text-muted"
                              href="/"
                              data-bs-toggle="dropdown"
                              //   aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Last 7 days
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item active" href="/">
                                Last 7 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 30 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 3 months
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <div className="h1 mb-3 me-2">6,782</div>
                        <div className="me-auto">
                          <span className="text-yellow d-inline-flex align-items-center lh-1">
                            0% Download SVG icon from
                            http://tabler-icons.io/i/minus
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon ms-1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="subheader">Active users</div>
                        <div className="ms-auto lh-1">
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle text-muted"
                              href="/"
                              data-bs-toggle="dropdown"
                              //   aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Last 7 days
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a className="dropdown-item active" href="/">
                                Last 7 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 30 days
                              </a>
                              <a className="dropdown-item" href="/">
                                Last 3 months
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row row-cards">
                    <div className="col-sm-6 col-lg-3">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="bg-primary text-white avatar">
                                {" "}
                                Download SVG icon from
                                http://tabler-icons.io/i/currency-dollar
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"></path>
                                  <path d="M12 3v3m0 12v3"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="col">
                              <div className="font-weight-medium">
                                132 Sales
                              </div>
                              <div className="text-muted">
                                12 waiting payments
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="bg-green text-white avatar">
                                {" "}
                                Download SVG icon from
                                http://tabler-icons.io/i/shopping-cart
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <circle cx="6" cy="19" r="2"></circle>
                                  <circle cx="17" cy="19" r="2"></circle>
                                  <path d="M17 17h-11v-14h-2"></path>
                                  <path d="M6 5l14 1l-1 7h-13"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="col">
                              <div className="font-weight-medium">
                                78 Orders
                              </div>
                              <div className="text-muted">32 shipped</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="bg-twitter text-white avatar">
                                {" "}
                                Download SVG icon from
                                http://tabler-icons.io/i/brand-twitter
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="col">
                              <div className="font-weight-medium">
                                623 Shares
                              </div>
                              <div className="text-muted">16 today</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                      <div className="card card-sm">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="bg-facebook text-white avatar">
                                {" "}
                                Download SVG icon from
                                http://tabler-icons.io/i/brand-facebook
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  ></path>
                                  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="col">
                              <div className="font-weight-medium">
                                132 Likes
                              </div>
                              <div className="text-muted">21 today</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row row-cards">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <p className="mb-3">
                            Using Storage <strong>6854.45 MB </strong>
                            of 8 GB
                          </p>
                          <div className="progress progress-separated mb-3">
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              aria-label="Regular"
                              style={{
                                width: "44%",
                              }}
                            ></div>
                            <div
                              className="progress-bar bg-info"
                              role="progressbar"
                              aria-label="System"
                              style={{
                                width: "19%",
                              }}
                            ></div>
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              aria-label="Shared"
                              style={{
                                width: "9%",
                              }}
                            ></div>
                          </div>
                          <div className="row">
                            <div className="col-auto d-flex align-items-center pe-2">
                              <span className="legend me-2 bg-primary"></span>
                              <span>Regular</span>
                              <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                                915MB
                              </span>
                            </div>
                            <div className="col-auto d-flex align-items-center px-2">
                              <span className="legend me-2 bg-info"></span>
                              <span>System</span>
                              <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                                415MB
                              </span>
                            </div>
                            <div className="col-auto d-flex align-items-center px-2">
                              <span className="legend me-2 bg-success"></span>
                              <span>Shared</span>
                              <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                                201MB
                              </span>
                            </div>
                            <div className="col-auto d-flex align-items-center ps-2">
                              <span className="legend me-2"></span>
                              <span>Free</span>
                              <span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted">
                                612MB
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card" style={{ height: "28rem" }}>
                        <div className="card-body card-body-scrollable card-body-scrollable-shadow">
                          <div className="divide-y">
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span className="avatar">JL</span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Jeffie Lewzey</strong> commented on
                                    your <strong>"I'm not a witch."</strong>{" "}
                                    post.
                                  </div>
                                  <div className="text-muted">yesterday</div>
                                </div>
                                <div className="col-auto align-self-center">
                                  <div className="badge bg-primary"></div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/002m.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    It's <strong>Mallory Hulme</strong>
                                    's birthday. Wish him well!
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                                <div className="col-auto align-self-center">
                                  <div className="badge bg-primary"></div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/003m.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Dunn Slane</strong> posted{" "}
                                    <strong>"Well, what do you want?"</strong>.
                                  </div>
                                  <div className="text-muted">today</div>
                                </div>
                                <div className="col-auto align-self-center">
                                  <div className="badge bg-primary"></div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/000f.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Emmy Levet</strong> created a new
                                    project <strong>Morning alarm clock</strong>
                                    .
                                  </div>
                                  <div className="text-muted">4 days ago</div>
                                </div>
                                <div className="col-auto align-self-center">
                                  <div className="badge bg-primary"></div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/001f.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Maryjo Lebarree</strong> liked your
                                    photo.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span className="avatar">EP</span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Egan Poetz</strong> registered new
                                    client as <strong>Trilia</strong>.
                                  </div>
                                  <div className="text-muted">yesterday</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/002f.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Kellie Skingley</strong> closed a
                                    new deal on project{" "}
                                    <strong>Pen Pineapple Apple Pen</strong>.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/003f.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Christabel Charlwood</strong>{" "}
                                    created a new project for{" "}
                                    <strong>Wikibox</strong>.
                                  </div>
                                  <div className="text-muted">4 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span className="avatar">HS</span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Haskel Shelper</strong> change
                                    status of <strong>Tabler Icons</strong> from{" "}
                                    <strong>open</strong> to{" "}
                                    <strong>closed</strong>.
                                  </div>
                                  <div className="text-muted">today</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/006m.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Lorry Mion</strong> liked{" "}
                                    <strong>Tabler UI Kit</strong>.
                                  </div>
                                  <div className="text-muted">yesterday</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/004f.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Leesa Beaty</strong> posted new
                                    video.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/007m.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Perren Keemar</strong> and 3 others
                                    followed you.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span className="avatar">SA</span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Sunny Airey</strong> upload 3 new
                                    photos to category{" "}
                                    <strong>Inspirations</strong>.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/009m.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Geoffry Flaunders</strong> made a{" "}
                                    <strong>$10</strong> donation.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/010m.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Thatcher Keel</strong> created a
                                    profile.
                                  </div>
                                  <div className="text-muted">3 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/005f.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Dyann Escala</strong> hosted the
                                    event <strong>Tabler UI Birthday</strong>.
                                  </div>
                                  <div className="text-muted">4 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span
                                    className="avatar"
                                    style={{
                                      backgroundImage:
                                        "url('./static/avatars/006f.jpg')",
                                    }}
                                  ></span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Avivah Mugleston</strong> mentioned
                                    you on <strong>Best of 2020</strong>.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-auto">
                                  <span className="avatar">AA</span>
                                </div>
                                <div className="col">
                                  <div className="text-truncate">
                                    <strong>Arlie Armstead</strong> sent a
                                    Review Request to{" "}
                                    <strong>Amanda Blake</strong>.
                                  </div>
                                  <div className="text-muted">2 days ago</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
                <div className="col-12">
                  <div className="card card-md">
                    <div className="card-stamp card-stamp-lg">
                      <div className="card-stamp-icon bg-primary">
                        Download SVG icon from http://tabler-icons.io/i/ghost
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7"></path>
                          <line x1="10" y1="10" x2="10.01" y2="10"></line>
                          <line x1="14" y1="10" x2="14.01" y2="10"></line>
                          <path d="M10 14a3.5 3.5 0 0 0 4 0"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-10">
                          <h3 className="h1">Tabler Icons</h3>
                          <div className="markdown text-muted">
                            All icons come from the Tabler Icons set and are
                            MIT-licensed. Visit
                            <a
                              href="https://tabler-icons.io"
                              target="_blank"
                              rel="noopener"
                            >
                              tabler-icons.io
                            </a>
                            , download any of the 2681 icons in SVG, PNG
                            or&nbsp;React and use them in your favourite design
                            tools.
                          </div>
                          <div className="mt-3">
                            <a
                              href="https://tabler-icons.io"
                              className="btn btn-primary"
                              target="_blank"
                              rel="noopener"
                            >
                              Download icons
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer footer-transparent d-print-none">
            <div className="container-xl">
              <div className="row text-center align-items-center flex-row-reverse">
                <div className="col-lg-auto ms-lg-auto">
                  <ul className="list-inline list-inline-dots mb-0">
                    <li className="list-inline-item">
                      <a href="./docs/index.html" className="link-secondary">
                        Documentation
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="./license.html" className="link-secondary">
                        License
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://github.com/tabler/tabler"
                        target="_blank"
                        className="link-secondary"
                        rel="noopener"
                      >
                        Source code
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://github.com/sponsors/codecalm"
                        target="_blank"
                        className="link-secondary"
                        rel="noopener"
                      >
                        Download SVG icon from http://tabler-icons.io/i/heart
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon text-pink icon-filled icon-inline"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
                        </svg>
                        Sponsor
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                  <ul className="list-inline list-inline-dots mb-0">
                    <li className="list-inline-item">
                      Copyright © 2022
                      <a href="." className="link-secondary">
                        Tabler
                      </a>
                      . All rights reserved
                    </li>
                    <li className="list-inline-item">
                      Generated 2022-09-24 09:54 UTC
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Home;
