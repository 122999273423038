import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  allUserData: any;
  allLoading: boolean;
  currLoading: boolean;
  currUserData: any;
}

//   export interface ViewCurricData {
//     programme: string,
//     session: string,
//   }

const initialState: Values = {
  currLoading: false,
  currUserData: null,
  allLoading: false,
  allUserData: null,
};

export const getCurrentUser = createAsyncThunk(
  "viewUserData",
  async (id: any) => {
    return axiosInstance
      .get(`/users/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const getAllUsers = createAsyncThunk("ViewAllUsersData", async () => {
  return axiosInstance
    .get("/users")
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((err) => {
      return err;
    });
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state) => {
      state.currLoading = false;
      state.currUserData = null;
      state.allLoading = false;
      state.allUserData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state) => {
      state.currLoading = true;
      state.currUserData = null;
      state.allLoading = false;
      state.allUserData = null;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
      state.currLoading = false;
      state.currUserData = payload;
      state.allLoading = false;
      state.allUserData = null;
    });
    builder.addCase(getCurrentUser.rejected, (state, { payload }) => {
      state.currLoading = false;
      state.currUserData = payload;
      state.allLoading = false;
      state.allUserData = null;
    });
    builder.addCase(getAllUsers.pending, (state) => {
      state.currLoading = false;
      state.currUserData = null;
      state.allLoading = true;
      state.allUserData = null;
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.currLoading = false;
      state.currUserData = null;
      state.allLoading = false;
      state.allUserData = payload;
    });
    builder.addCase(getAllUsers.rejected, (state, { payload }) => {
      state.currLoading = false;
      state.currUserData = null;
      state.allLoading = false;
      state.allUserData = payload;
    });
  },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
