import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Sessions from "../reusable/sessions";
import BtnLoader from "../reusable/btnLoader";
import { ViewAllPrograms } from "../../state/programs/programs";
import { ViewStudentsRegisteredCourses } from "../../state/programs/studentReg";
const CourseRegDetails = () => {
  const [printValues, setPrintValues] = useState<any>();
  const [coursesData, setCoursesData] = useState<[]>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    viewAllProg,
    progStudReg: { data, loading },
  } = useSelector((state: RootState) => state);
  const initialValues = {
    programme: "",
    session: "",
    semester: "",
    level: "",
  };

  const SignInSchema = Yup.object().shape({
    programme: Yup.string().required("Programme is required"),
    session: Yup.string().required("Session is required"),
    semester: Yup.string().required("Semester is required"),
    level: Yup.string().required("Level is required"),
  });

  useEffect(() => {
    if (!viewAllProg?.data) {
      dispatch(ViewAllPrograms());
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setCoursesData(data?.data);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message?.name[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data]);

  const handleSubmit = (values: any) => {
    // console.log(values);
    dispatch(ViewStudentsRegisteredCourses(values));
    setPrintValues(values);
  };

  return (
    <div>
      <ToastContainer />
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Programme Course Registration</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            Display the list of all courses registered by every student of a
            specific Programme per a semester.
          </p>
          <hr className="mt-1" />

          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <Field
                        as="select"
                        name="programme"
                        type="programme"
                        //   defaultValue={initialprogramme}
                        className={
                          errors.programme && touched.programme
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select programme ...
                        </option>
                        {viewAllProg?.data?.data?.map(
                          (item: any, index: number) => (
                            <option value={item.programme} key={index}>
                              {item.programmeName}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        name="programme"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-2 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-2 my-2">
                      <Field
                        as="select"
                        name="semester"
                        type="semester"
                        //   defaultValue={initialsemester}
                        className={
                          errors.semester && touched.semester
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select semester ...
                        </option>
                        <option value="0">First</option>
                        <option value="1">Second</option>
                        <option value="2">ERP</option>
                      </Field>
                      <ErrorMessage
                        name="semester"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-2 my-2">
                      <Field
                        as="select"
                        name="level"
                        type="level"
                        //   defaultValue={initiallevel}
                        className={
                          errors.level && touched.level
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select level...
                        </option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="400">400</option>
                        <option value="500">500</option>
                        <option value="600">600</option>
                        <option value="700">700</option>
                      </Field>
                      <ErrorMessage
                        name="level"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* REQUEST RESPONSE TABLE STARTS */}
        <div>
          {coursesData && coursesData?.length === 0 ? (
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title py-2">No Results Found</h2>
                </div>
              </div>
            </div>
          ) : (
            coursesData && (
              <div className="container my-4">
                <div className="container-xl">
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <h5 className="page-title py-2">Search Results</h5>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Matric Number</th>
                          <th>Name</th>
                          <th>Courses</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coursesData &&
                          coursesData.map((item: any, index: number) => (
                            <tr className="" key={item?.id}>
                              <td>{index + 1}</td>
                              <td>{item?.matricNumber}</td>
                              <td className="text-muted">
                                {item?.studentName}
                              </td>
                              <td className="text-muted">
                                {item?.studentCourses.map(
                                  (course: any, i: number) => (
                                    <span key={course.id}>
                                      {i + 1 === item.studentCourses.length
                                        ? course.courseCode
                                        : course.courseCode + ", "}
                                    </span>
                                  )
                                )}
                              </td>
                              <td>
                                <form
                                  method="post"
                                  action="https://bowenstudent.bowen.edu.ng/v2/ssprintcoursereg.php"
                                  target="_blank"
                                >
                                  <input
                                    name="session"
                                    value={printValues?.session}
                                    hidden
                                  />
                                  <input
                                    name="semester"
                                    value={printValues?.semester}
                                    hidden
                                  />
                                  <input
                                    name="matric"
                                    value={item?.matricNumber}
                                    hidden
                                  />
                                  <input
                                    name="print"
                                    value="Print"
                                    className="btn btn-secondary px-4 py-1"
                                    type="submit"
                                  />
                                </form>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {/* REQUEST RESPONSE TABLE ENDS */}
      </div>
    </div>
  );
};

export default CourseRegDetails;
