// ScrollToTop.jsx
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearStudentData } from "../../state/students/students";
import { clearStaffData } from "../../state/staff/staff";
import { clearAddCourses } from "../../state/courses/addCourse";
import { clearViewCurriculum } from "../../state/courses/curricullum";
import { clearStudRegData } from "../../state/programs/studentReg";
import { clearViewStudRegData } from "../../state/students/viewReg";
import { clearRegStudCourseMultipleData } from "../../state/courses/regStudsMultiple";
import { clearViewAllocations } from "../../state/programs/viewAllocations";
import { clearAllocateStudents } from "../../state/programs/allocate";
import { clearViewDistinctCourses } from "../../state/courses/distinctCourses";
import { clearViewScore } from "../../state/courses/viewScore";
import { clearFeeExemptData } from "../../state/payments/feeExempt";
import { clearStaffCourseMapData } from "../../state/staff/staffCourseMap";
import { clearAllUsers } from "../../state/user/allUsers";
import { clearStaffRole } from "../../state/staff/assignStaffRoles";
import { clearCreateUser } from "../../state/user/createUser";
import { clearUserUpdates } from "../../state/user/userActions";

function ScrollToTop(props: any) {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // ACTIONS TO CLEAR REDUX STATES ON EACH PAGE CHANGE WHEN NECESSARY
    dispatch(clearStudentData());
    dispatch(clearStaffData());
    dispatch(clearAddCourses());
    dispatch(clearViewCurriculum());
    dispatch(clearStudRegData());
    dispatch(clearViewStudRegData());
    dispatch(clearRegStudCourseMultipleData());
    dispatch(clearViewAllocations());
    dispatch(clearAllocateStudents());
    dispatch(clearViewDistinctCourses());
    dispatch(clearViewScore());
    dispatch(clearFeeExemptData());
    dispatch(clearStaffCourseMapData());
    dispatch(clearAllUsers());
    dispatch(clearStaffRole());
    dispatch(clearCreateUser());
    dispatch(clearUserUpdates());
  }, [location]);

  return props.children;
}

export default ScrollToTop;
