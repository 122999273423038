import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import BtnLoader from "./reusable/btnLoader";
import Sessions from "./reusable/sessions";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../state/store";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  RegisterStudentCourses,
  studRegValues,
} from "../state/courses/regStudsMultiple";

const RegCourseMultiple = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    regStudCourse: { data, loading },
  } = useSelector((state: RootState) => state);
  const initialValues = {
    matricNumbers: "",
    courses: "",
    session: "",
    semester: "",
  };

  const SignInSchema = Yup.object().shape({
    matricNumbers: Yup.string().required(
      "At least one matric number is required"
    ),
    courses: Yup.string().required("At least one course is required"),
    session: Yup.string().required("Session is required"),
    semester: Yup.string().required("Semester is required"),
  });

  useEffect(() => {
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        // setCoursesData(data?.data);
        toast.success(
          `${
            data?.data?.message
              ? data?.data?.message
              : "Registration successful"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? `${data?.message?.courseCodes[0]} 
              ${data?.message?.matricNumbers[0]} 
              ${data?.message?.semester[0]} ${data?.message?.session[0]}`
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data]);

  const handleSubmit = ({ matricNumbers, courses, session, semester }: any) => {
    // console.log(
    //   matricNumbers.replace(/\s/g, "").split(","),
    //   courses.trim().split(","),
    //   session,
    //   semester
    // );
    const data: studRegValues = {
      matricNumbers: matricNumbers.replace(/\s/g, "").split(","),
      courseCodes: courses.trim().split(","),
      session,
      semester,
    };
    // console.log(data);
    dispatch(RegisterStudentCourses(data));
  };

  return (
    <div>
      <ToastContainer />
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="">Register Courses (Multiple)</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">Register new course(s) for student</p>
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-12 my-2">
                      <Field
                        as="textarea"
                        name="matricNumbers"
                        id="matricNumbers"
                        style={{
                          height: "200px",
                        }}
                        placeholder="Matric number(s): BU19PST2011, BU19PST2012, BU19PST2012"
                        autoComplete="off"
                        className={
                          errors.matricNumbers && touched.matricNumbers
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="matricNumbers"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-12 my-2">
                      <Field
                        as="textarea"
                        name="courses"
                        id="courses"
                        style={{
                          height: "200px",
                        }}
                        placeholder="Course title(s): BOW 123,BOW 201,GST 102"
                        autoComplete="off"
                        className={
                          errors.courses && touched.courses
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="courses"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="semester"
                        type="semester"
                        //   defaultValue={initialsemester}
                        className={
                          errors.semester && touched.semester
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select semester ...
                        </option>
                        <option value="0">First</option>
                        <option value="1">Second</option>
                        <option value="2">ERP</option>
                      </Field>
                      <ErrorMessage
                        name="semester"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Register
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegCourseMultiple;
