import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import BtnLoader from "../reusable/btnLoader";
import Sessions from "../reusable/sessions";
import * as Yup from "yup";
import {
  AddFeeExempt,
  AddFeeExemption,
  DeleteExemption,
  ViewFeeExempt,
  ViewFeeExemptions,
} from "../../state/payments/feeExempt";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../modals/DeleteModal";
import { Modal } from "react-bootstrap";
import moment from "moment";
import LinearDeterminate from "../reusable/LinearDeterminate";
import { Box, Pagination } from "@mui/material";

const FeeExcemptionDetails = () => {
  const [exemptionData, setExemptionData] = useState<[] | any>();
  const [enteredSession, setEnteredSession] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<any>();
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>();
  const [exemptModal, setExemptModal] = useState<any>();
  const [exemptModalShow, setExemptModalShow] = useState<boolean>();

  //   SEARCH FIELD INPUT STATES
  const [searchField, setSearchField] = useState<string>("");
  const [exemptedSearchField, setExemptedSearchField] = useState<string>("");

  //   PAGINATION STATES
  const [paginate, setPaginate] = useState<any>();
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState<number>(1);

  const dispatch = useDispatch<AppDispatch>();
  const {
    feeExempt: {
      loading,
      data,
      addExemptData,
      addExemptLoading,
      delExemptData,
      delExemptLoading,
    },
  } = useSelector((state: RootState) => state);
  const initialValues: ViewFeeExempt = {
    session: "",
  };
  const ValidationSchema = Yup.object().shape({
    session: Yup.string().required("Session is required"),
  });
  //   const initialExemptValues: AddFeeExcempt = {
  //     matricNumber: "",
  //     session: "",
  //     expiration: "",
  //     reason: "",
  //   };
  const ExemptValidationSchema = Yup.object().shape({
    session: Yup.string().required("Session is required"),
    matricNumber: Yup.string().required("Matric number is required"),
    expiration: Yup.string().required("Expiration is required"),
    reason: Yup.string()
      .required("Reason is required")
      .max(100)
      .label("Reason"),
  });

  useEffect(() => {
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setExemptionData(data?.data);
      }
      if (data?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (addExemptData) {
      if (addExemptData?.name === "AxiosError") {
        toast.error(
          `${
            addExemptData?.message
              ? addExemptData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (addExemptData?.success === true) {
        //   setExemptionData(data?.data);
        dispatch(ViewFeeExemptions({ session: enteredSession }));
        toast.success(
          addExemptData?.message
            ? addExemptData?.message
            : "Student successfully exempted.",
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (addExemptData?.success === false) {
        toast.error("Something went wrong please try again.", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (delExemptData) {
      if (delExemptData?.name === "AxiosError") {
        toast.error(
          `${
            delExemptData?.message
              ? delExemptData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (delExemptData?.success === true) {
        //   setExemptionData(data?.data);
        dispatch(ViewFeeExemptions({ session: enteredSession }));
        toast.success(
          delExemptData?.message
            ? delExemptData?.message
            : "Student exemption deleted.",
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (delExemptData?.success === false) {
        toast.error("Something went wrong please try again.", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [data, addExemptData, delExemptData]);

  const handleSubmit = (values: ViewFeeExempt) => {
    setEnteredSession(values?.session);
    dispatch(ViewFeeExemptions(values));
  };

  const handleExemptSubmit = (values: AddFeeExempt) => {
    // console.log(values);
    dispatch(AddFeeExemption(values));
    setExemptModalShow(false);
  };

  const handleExemptDelete = (id: number) => {
    // console.log(id);
    dispatch(DeleteExemption(id));
    setDeleteModalShow(false);
  };

  const handleChange = (e: any, page: number) => {
    // console.log(page);
    setPage(page);
    dispatch(ViewFeeExemptions({ session: enteredSession }));
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">
        {addExemptLoading && <LinearDeterminate />}
        {delExemptLoading && <LinearDeterminate />}
      </div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Fee Exemption</h2>
                  <p className="mb-0">
                    Exempt students from constraints of payments
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <hr className="mt-1" />

          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-6 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-6 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Get all
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* REQUEST RESPONSE TABLE STARTS */}
        <div>
          {exemptionData && exemptionData?.length === 0 ? (
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title py-2">No Results Found</h2>
                </div>
              </div>
            </div>
          ) : (
            exemptionData && (
              <div className="container my-4 ">
                <div className="container-xl mb-2">
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <h5 className="page-title py-2">
                        {enteredSession} Students
                      </h5>
                    </div>
                    <div className="col text-center text-md-end search m-0 px-2">
                      <input
                        type={"text"}
                        name="search"
                        id="search"
                        placeholder="Search"
                        className={"py-2 search--input px-3 shadow-none m-0"}
                        onChange={(e) => setSearchField(e.target.value)}
                      />
                      <i
                        className={"fa-solid fa-magnifying-glass fa-fw"}
                        // onClick={togglePassword}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Matric Number</th>
                          <th>Name</th>
                          <th>Programme</th>
                          <th>Semester</th>
                          <th>Level</th>
                          <th>Spill Over</th>
                          {/* <th className="w-1"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {exemptionData &&
                          exemptionData?.students?.itemList
                            ?.filter(
                              (item: any) =>
                                item?.firstName
                                  .toLowerCase()
                                  .includes(searchField.toLowerCase()) ||
                                item?.lastName
                                  .toLowerCase()
                                  .includes(searchField.toLowerCase()) ||
                                item?.matricNumber
                                  .toLowerCase()
                                  .includes(searchField.toLowerCase())
                            )
                            .slice()
                            ?.map((item: any, index: number) => (
                              <tr className="" key={item?.id}>
                                <td>{index + 1}</td>
                                <td>{item?.matricNumber}</td>
                                <td className="text-muted">
                                  {item?.firstName} {item?.lastName}
                                </td>
                                <td className="text-muted">
                                  {item?.programme}
                                </td>
                                <td className="text-muted">
                                  {/* new
                                  {item?.semester === 0 && "First"} */}
                                  {item?.semester === 0
                                    ? "First"
                                    : item?.semester === 1
                                    ? "Second"
                                    : item?.semester === 2
                                    ? "ERP"
                                    : ""}
                                </td>
                                <td className="text-muted">{item?.level}</td>
                                <td className="text-muted">
                                  {item?.spill === 1 ? "Yes" : "No"}
                                </td>
                                <td className="text-muted">
                                  <button
                                    className={
                                      "btn btn-success w-100 mx-auto py-1 px-3"
                                    }
                                    onClick={() => {
                                      setExemptModalShow(true);
                                      setExemptModal(item);
                                    }}
                                  >
                                    Exempt
                                  </button>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                    {/* PAGINATION STARTS */}
                    <div className="py-3">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Pagination
                          count={paginate && paginate?.lastPage}
                          onChange={handleChange}
                          showFirstButton
                          showLastButton
                        />
                      </Box>
                    </div>
                    {/* PAGINATION ENDS */}
                  </div>
                </div>

                {/* EXEMPTED STUDENTS STARTS*/}
                <div className="container-xl mt-5 mb-2">
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <h5 className="page-title py-2">
                        {enteredSession} Exempted Students
                      </h5>
                    </div>
                    <div className="col text-center text-md-end search m-0 px-2">
                      <input
                        type={"text"}
                        name="search"
                        id="search"
                        placeholder="Search"
                        className={"py-2 search--input px-3 shadow-none m-0"}
                        onChange={(e) => setExemptedSearchField(e.target.value)}
                      />
                      <i
                        className={"fa-solid fa-magnifying-glass fa-fw"}
                        // onClick={togglePassword}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Matric Number</th>
                          <th>Name</th>
                          <th>Expiration</th>
                          <th>Reason</th>
                          <th>Added</th>
                          {/* <th className="w-1"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {exemptionData &&
                          exemptionData?.exemptedStudents
                            ?.filter((item: any) =>
                              item?.matricNumber
                                .toLowerCase()
                                .includes(exemptedSearchField.toLowerCase())
                            )
                            .slice()
                            ?.map((item: any, index: number) => (
                              <tr className="" key={item?.id}>
                                <td>{index + 1}</td>
                                <td>{item?.matricNumber}</td>
                                <td className="text-muted">
                                  {item?.firstName} {item?.lastName}
                                </td>
                                <td className="text-muted">
                                  {moment(
                                    item?.expiration,
                                    moment.HTML5_FMT.DATETIME_LOCAL_MS
                                  ).format("Do MMM  YYYY")}
                                </td>
                                <td className="text-muted">{item?.reason}</td>
                                <td className="text-muted">
                                  {moment(
                                    item?.createdAt,
                                    moment.HTML5_FMT.DATETIME_LOCAL_MS
                                  ).format("Do MMM  YYYY, h:mm:ss a")}
                                </td>
                                <td className="text-muted">
                                  <button
                                    className={
                                      "btn btn-danger w-100 mx-auto py-1 px-3"
                                    }
                                    onClick={() => {
                                      setDeleteModalShow(true);
                                      setDeleteModal(item);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                        {/* EXEMPTED STUDENTS ENDS */}
                      </tbody>
                    </table>

                    {/* DELETE MODAL STARTS */}
                    <div className="modal-blur ">
                      <DeleteModal
                        modal={deleteModal}
                        show={deleteModalShow}
                        onHide={() => setDeleteModalShow(false)}
                        size={"modal-md"}
                        header={"Delete Exemption"}
                      >
                        <Modal.Body className="p-4 pt-0 pb-4">
                          <p className="py-2 fw-bold">
                            You are about to remove {deleteModal?.matricNumber}{" "}
                            from the fee exemption list. Are you sure you want
                            to delete ???
                          </p>
                          <div className="modal-footer pb-0 mb-0">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => setDeleteModalShow(false)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                handleExemptDelete(deleteModal?.id);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </Modal.Body>
                      </DeleteModal>
                    </div>
                    {/* DELETE MODAL ENDS */}

                    {/* EXEMPT MODAL STARTS */}
                    <div className="modal-blur ">
                      <DeleteModal
                        modal={exemptModal}
                        show={exemptModalShow}
                        onHide={() => setExemptModalShow(false)}
                        size={"modal-md"}
                        header={"Add Exemption"}
                      >
                        <Modal.Body className="p-4 pt-0 pb-2">
                          <Formik
                            initialValues={{
                              matricNumber: exemptModal?.matricNumber,
                              session: exemptModal?.session,
                              expiration: "",
                              reason: "",
                            }}
                            validationSchema={ExemptValidationSchema}
                            onSubmit={handleExemptSubmit}
                          >
                            {(formik) => {
                              const { errors, touched, isValid, dirty } =
                                formik;
                              return (
                                <Form>
                                  <div className="row">
                                    <div className="col-12 my-2">
                                      <label
                                        htmlFor="matricNumber"
                                        className="fw-bold"
                                      >
                                        Matric number
                                      </label>
                                      <Field
                                        type="text"
                                        name="matricNumber"
                                        id="matricNumber"
                                        placeholder="Enter matric number"
                                        autoComplete="off"
                                        className={
                                          errors.matricNumber &&
                                          touched.matricNumber
                                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                                        }
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="matricNumber"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                    <div className="col-12 my-2">
                                      <label
                                        htmlFor="Session"
                                        className="fw-bold"
                                      >
                                        Session
                                      </label>
                                      <Field
                                        type="text"
                                        name="session"
                                        id="session"
                                        placeholder="Enter session"
                                        autoComplete="off"
                                        className={
                                          errors.session && touched.session
                                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                                        }
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="session"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                    <div className="col-12 my-2">
                                      <label
                                        htmlFor="expiration"
                                        className="fw-bold"
                                      >
                                        Expiration date
                                      </label>
                                      <Field
                                        type="date"
                                        name="expiration"
                                        id="expiration"
                                        autoComplete="off"
                                        className={
                                          errors.expiration &&
                                          touched.expiration
                                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                                        }
                                      />
                                      <ErrorMessage
                                        name="expiration"
                                        component="div"
                                        className="error"
                                      />
                                    </div>
                                    <div className="col-md-12 my-2">
                                      <label
                                        htmlFor="reason"
                                        className="fw-bold"
                                      >
                                        Reason
                                      </label>
                                      <Field
                                        as="textarea"
                                        name="reason"
                                        type="reason"
                                        //   defaultValue={initialreason}
                                        className={
                                          errors.reason && touched.reason
                                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                                        }
                                      ></Field>
                                      <ErrorMessage
                                        name="reason"
                                        component="div"
                                        className="error"
                                      />
                                    </div>

                                    <div className="col-12 my-2">
                                      <button
                                        type="submit"
                                        className={
                                          !(dirty && isValid)
                                            ? "disabled-btn btn btn-success w-100 mx-auto py-3 px-3"
                                            : "btn btn-success w-100 mx-auto py-3 px-3"
                                        }
                                        disabled={!(dirty && isValid)}
                                      >
                                        Exempt
                                      </button>
                                    </div>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                          <div className="modal-footer"></div>
                        </Modal.Body>
                      </DeleteModal>
                    </div>
                    {/* EXEMPT MODAL ENDS */}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {/* REQUEST RESPONSE TABLE ENDS */}
      </div>
    </div>
  );
};

export default FeeExcemptionDetails;
