import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../state/auth";
import StudentReducer from "../state/students/students";
import SuspendStudReducer from "./students/suspendStud";
import StaffReducer from "./staff/staff";
import AddStaffReducer from "./staff/addStaff";
import ViewStudentRegistrationReducer from "./students/viewReg";
import ViewAllProgReducer from "./programs/programs";
import ViewCurricReducer from "./courses/curricullum";
import AddCourseReducer from "./courses/addCourse";
import UserReducer from "./user/user";
import allStaffReducer from "./staff/allStaff";
import ProgramStudRegReducer from "./programs/studentReg";
import RegStudCourseReducer from "./courses/regStudsMultiple";
import AllocateStudReducer from "./programs/allocate";
import ViewAllocationsReducer from "./programs/viewAllocations";
import viewAllocationProgramsReducer from "./programs/allocationPrograms";
import distinctCoursesReducer from "./courses/distinctCourses";
import viewScoreReducer from "./courses/viewScore";
import lateCourseRegReducer from "./courses/lateCourseReg";
import feeExemptReducer from "./payments/feeExempt";
import harmoniseCourseReducer from "./courses/harmoniseCourse";
import StaffCourseMapReducer from "./staff/staffCourseMap";
import allUsersReducer from "./user/allUsers";
import createUsersReducer from "./user/createUser";
import assignStaffRolesReducer from "./staff/assignStaffRoles";
import userActionsReducer from "./user/userActions";
// import ApproveRemoveReducer from "./students/approveRemove";

export const store = configureStore({
  reducer: {
    // AUTHENTICATION
    auth: AuthReducer,

    // STUDENTS
    stud: StudentReducer,
    suspendStud: SuspendStudReducer,

    // COURSES
    viewStudReg: ViewStudentRegistrationReducer,
    regStudCourse: RegStudCourseReducer,
    allocateStud: AllocateStudReducer,
    viewAllocations: ViewAllocationsReducer,
    viewAllocationProg: viewAllocationProgramsReducer,
    distinctCourses: distinctCoursesReducer,
    viewScore: viewScoreReducer,
    lateCourseReg: lateCourseRegReducer,

    // PROGRAM
    viewAllProg: ViewAllProgReducer,
    viewCurric: ViewCurricReducer,
    addCourses: AddCourseReducer,
    progStudReg: ProgramStudRegReducer,

    // USER
    user: UserReducer,
    allUsers: allUsersReducer,
    createUser: createUsersReducer,
    userActions: userActionsReducer,

    // STAFF
    staff: StaffReducer,
    addStaff: AddStaffReducer,
    allStaff: allStaffReducer,
    staffCourseMap: StaffCourseMapReducer,
    assignStaffRoles: assignStaffRolesReducer,

    // PAYMENTS
    feeExempt: feeExemptReducer,
    harmoniseCourse: harmoniseCourseReducer,

    // ApproveRemove: ApproveRemoveReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
