import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import BtnLoader from "./reusable/btnLoader";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Sessions from "./reusable/sessions";
import { useDispatch, useSelector } from "react-redux";
import {
  ViewRegData,
  ViewStudRegData,
  ApproveCourse,
  RemoveCourse,
} from "../state/students/viewReg";
import { AppDispatch, RootState } from "../state/store";
// import { ApproveCourse, RemoveCourse } from "../state/students/approveRemove";
import LinearDeterminate from "./reusable/LinearDeterminate";

const CourseReg = () => {
  const [coursesData, setCoursesData] = useState<[]>();
  const [printValues, setPrintValues] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    viewStudReg: {
      data,
      loading,
      approveData,
      approveLoading,
      removeData,
      removeLoading,
    },
  } = useSelector((state: RootState) => state);

  const initialValues: ViewRegData = {
    matricNumber: "",
    session: "",
    semester: "",
  };

  const SignInSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Matric number is required"),
    session: Yup.string(),
    semester: Yup.string(),
  });

  useEffect(() => {
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setCoursesData(data?.data);
      }
      if (data?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (removeData) {
      if (removeData?.name === "AxiosError") {
        toast.error(
          `${
            removeData?.message
              ? removeData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (removeData?.success === true) {
        // setCoursesData(removeData?.data);
        dispatch(ViewStudRegData(printValues));
        toast.success(
          `${
            removeData?.data?.message
              ? removeData?.data?.message
              : "Course Removed Succesfully"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (removeData?.success === false) {
        toast.error(
          `${
            removeData?.data?.message
              ? removeData?.data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
    if (approveData) {
      if (approveData?.name === "AxiosError") {
        toast.error(
          `${
            approveData?.message
              ? approveData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (approveData?.success === true) {
        // dispatch(ViewStudRegData(printValues));
        toast.success(
          `${
            approveData?.data?.message
              ? approveData?.data?.message
              : "Course Approved Succesfully"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (approveData?.success === false) {
        dispatch(ViewStudRegData(printValues));
        toast.error(
          `${
            approveData?.message
              ? approveData?.message?.name[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data, approveData, removeData]);

  const handleSubmit = ({ matricNumber, semester, session }: any) => {
    if (semester.length === 0 && session.length === 0) {
      dispatch(ViewStudRegData({ matricNumber }));
    } else if (semester.length === 0) {
      dispatch(ViewStudRegData({ matricNumber, session }));
    } else if (session.length === 0) {
      dispatch(ViewStudRegData({ matricNumber, semester }));
    } else {
      dispatch(ViewStudRegData({ matricNumber, semester, session }));
    }
    setPrintValues({ matricNumber, semester, session });
  };

  const handleApprove = (id: number) => {
    dispatch(ApproveCourse(id));
  };

  const handleRemove = (id: number) => {
    dispatch(RemoveCourse(id));
  };

  //   const handleCourseRegPrint = (data: any) => {
  //     axios.post("https://bowenstudent.bowen.edu.ng/v2/ssprintcoursereg.php", data);
  // (    )
  //   }

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">{approveLoading && <LinearDeterminate />}</div>
      <div className="fixed-top">{removeLoading && <LinearDeterminate />}</div>

      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">View Course Registration</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            Display the list of courses registered by a student and the current
            status
          </p>
          <hr className="mt-1" />

          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-3 my-2">
                      <Field
                        type="text"
                        name="matricNumber"
                        id="matricNumber"
                        placeholder="Enter matric number"
                        autoComplete="off"
                        className={
                          errors.matricNumber && touched.matricNumber
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="matricNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      <Field
                        as="select"
                        name="semester"
                        type="semester"
                        //   defaultValue={initialsemester}
                        className={
                          errors.semester && touched.semester
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select semester ...
                        </option>
                        <option value="0">First</option>
                        <option value="1">Second</option>
                        <option value="2">ERP</option>
                      </Field>
                      <ErrorMessage
                        name="semester"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-3 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* REQUEST RESPONSE TABLE STARTS */}
        <div>
          {coursesData && coursesData?.length === 0 ? (
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title py-2">No Results Found</h2>
                </div>
              </div>
            </div>
          ) : (
            coursesData && (
              <div className="container my-4">
                <div className="container-xl">
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <h5 className="page-title py-2">Search Results</h5>
                    </div>
                    <div className="col text-center text-md-end">
                      <form
                        method="post"
                        action="https://bowenstudent.bowen.edu.ng/v2/ssprintcoursereg.php"
                        target="_blank"
                      >
                        <input
                          name="session"
                          value={printValues?.session}
                          hidden
                        />
                        <input
                          name="semester"
                          value={printValues?.semester}
                          hidden
                        />
                        <input
                          name="matric"
                          value={printValues?.matricNumber}
                          hidden
                        />
                        <input
                          name="print"
                          value="Print"
                          className="btn btn-secondary px-5 py-2"
                          type="submit"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Course Code</th>
                          <th>Course Title</th>
                          <th>Course Type</th>
                          <th>Unit</th>
                          <th>Programme</th>
                          <th>Session</th>
                          <th>Semester</th>
                          <th>Level</th>
                          {/* <th className="w-1"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {coursesData &&
                          coursesData.map((item: any, index: number) => (
                            <tr className="" key={item?.id}>
                              <td>{index + 1}</td>
                              <td>{item?.courseCode}</td>
                              <td className="text-muted">
                                {item?.courseTitle}
                              </td>
                              <td className="text-muted">{item?.courseType}</td>
                              <td className="text-muted">{item?.unit}</td>
                              <td className="text-muted">{item?.programme}</td>
                              <td className="text-muted">{item?.session}</td>
                              <td className="text-muted">
                                {item?.semesterName}
                              </td>
                              <td className="text-muted">{item?.level}</td>
                              <td className="text-muted">
                                <button
                                  className="btn btn-danger py-1"
                                  onClick={() => handleRemove(item?.id)}
                                >
                                  Remove
                                </button>
                              </td>
                              <td className="text-muted">
                                <button
                                  className="btn btn-success py-1"
                                  onClick={() => handleApprove(item?.id)}
                                >
                                  Approve
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {/* REQUEST RESPONSE TABLE ENDS */}
      </div>
    </div>
  );
};

export default CourseReg;
