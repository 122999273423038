import React from "react";
import Modal from "react-bootstrap/Modal";

export default function DeleteModal({
  onHide,
  show,
  modal,
  children,
  size,
  header,
}: any) {
  return (
    <Modal
      onHide={onHide}
      show={show}
      className="modal-blur"
      dialogClassName={size ? size : "modal-lg"}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header className="border-0 py-3">
        <h5 className=" fw-bold">{header}</h5>
        <i
          className="btn-close shadow-none "
          role="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
          aria-hidden="true"
        />
      </Modal.Header>
      {children}
    </Modal>
  );
}
