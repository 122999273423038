import React from "react";
import SuspendStud from "../Components/SuspendStud";
import AppLayout from "../Layouts/AppLayout";

const SuspendStudent = () => {
    return (
        <AppLayout>
            <>
                <SuspendStud />
            </>
        </AppLayout>
    );
};

export default SuspendStudent;
