import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../helpers/axios";

export interface AuthState {
  loading: Boolean;
  data: any;
  error: any;
}
export interface Values {
  email: string;
  password: string;
}

const initialState: AuthState = {
  loading: false,
  data: null,
  error: null,
};

export const adminLogin = createAsyncThunk(
  "postLogin",
  async (data: Values) => {
    return axiosInstance
      .post(`/users/login`, data)
      .then((res) => {
        localStorage.setItem("buAdmToken", res?.data?.data?.token);
        localStorage.setItem("buAdmUsrId", res?.data?.data?.id);
        axiosInstance.defaults.headers.Authorization = `Bearer ${res?.data?.data?.token}`;
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    adminLogout: (state) => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.data = null;
    });
    builder.addCase(adminLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload;
    });
    builder.addCase(adminLogin.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.data = payload;
    });
  },
});

export const { adminLogout } = authSlice.actions;

export default authSlice.reducer;
