import React from "react";
import Curric from "../Components/courses/curric";
import AppLayout from "../Layouts/AppLayout";

const Curriculum = () => {
    return (
        <AppLayout>
            <>
                <Curric />
            </>
        </AppLayout>
    );
};

export default Curriculum;
