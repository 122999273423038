import React from "react";
import AddCourses from "../Pages/AddCourses";
import AddNewStaff from "../Pages/AddNewStaff";
import AllAcadStaff from "../Pages/AllAcadStaff";
import Allocate from "../Pages/Allocate";
import AssignStaffRoles from "../Pages/AssignStaffRole";
// import Login from "../Pages/Auth/Login";
import CheckUpdate from "../Pages/CheckUpdate";
import CourseMaterials from "../Pages/CourseMaterials";
import CourseRegistration from "../Pages/CourseRegistration";
// import CourseRegistration from "../Pages/CourseMaterials";
import CourseRegistrationPrints from "../Pages/CourseRegistrationPrints";
import Curriculum from "../Pages/Curriculum";
import Dashboard from "../Pages/Dashboard";
import FeeExcemption from "../Pages/FeeExemption";
import HarmoniseCourse from "../Pages/HarmoniseCourse";
import LateRegistration from "../Pages/LateRegistration";
import NewUser from "../Pages/NewUser";
import RegisterCourses from "../Pages/RegisterCourses";
import RegisterCoursesMultiple from "../Pages/RegisterCoursesMultiple";
import SearchStaff from "../Pages/SearchStaff";
import SearchStudent from "../Pages/SearchStudent";
import SearchStudentMatric from "../Pages/SearchStudentMatric";
import StaffCourseMap from "../Pages/StaffCourseMap";
import SuspendStudent from "../Pages/SuspendStudent";
import TransferStudents from "../Pages/TransferStudents";
import ViewAllocation from "../Pages/ViewAllocations";
import ViewAllPrograms from "../Pages/ViewAllPrograms";
import ViewCourseReg from "../Pages/ViewCourseReg";
import ViewScore from "../Pages/ViewScore";
import ViewStudentResult from "../Pages/ViewStudentResult";

interface Route {
  id: number;
  path: String;
  element: any;
}

const routes: Route[] = [
  // {
  //   id: 1,
  //   path: "/",
  //   element: <Login />,
  // },
  {
    id: 2,
    path: "/home",
    element: <Dashboard />,
  },

  //***************** STUDENTS *************************//
  {
    id: 3,
    path: "/students/check-update-info",
    element: <CheckUpdate />,
  },
  {
    id: 4,
    path: "/students/register-courses",
    element: <RegisterCourses />,
  },
  {
    id: 6,
    path: "/students/search-student",
    element: <SearchStudent />,
  },
  {
    id: 31,
    path: "/students/search-student-by-matric",
    element: <SearchStudentMatric />,
  },
  {
    id: 7,
    path: "/students/suspend-student-account",
    element: <SuspendStudent />,
  },
  {
    id: 8,
    path: "/students/transfer-student",
    element: <TransferStudents />,
  },
  {
    id: 9,
    path: "/students/view-course-registration",
    element: <CourseRegistration />,
  },
  {
    id: 10,
    path: "/students/student-result",
    element: <ViewStudentResult />,
  },

  //***************** STAFF *************************//
  {
    id: 11,
    path: "/staff/add-new-staff",
    element: <AddNewStaff />,
  },
  {
    id: 12,
    path: "/staff/all-academic-staff",
    element: <AllAcadStaff />,
  },
  {
    id: 13,
    path: "/staff/assign-staff-roles",
    element: <AssignStaffRoles />,
  },
  {
    id: 14,
    path: "/staff/check-update-info",
    element: <CheckUpdate />,
  },
  {
    id: 15,
    path: "/staff/search-staff",
    element: <SearchStaff />,
  },
  {
    id: 16,
    path: "/staff/staff-Course-mapping",
    element: <StaffCourseMap />,
  },
  {
    id: 17,
    path: "/staff/add-new-staff",
    element: <CourseRegistration />,
  },
  //***************** COURSES *************************//
  {
    id: 30,
    path: "/courses/add-courses",
    element: <AddCourses />,
  },
  {
    id: 18,
    path: "/courses/course-materials",
    element: <CourseMaterials />,
  },
  {
    id: 19,
    path: "/courses/course-registration-prints",
    element: <CourseRegistrationPrints />,
  },
  {
    id: 20,
    path: "/courses/curriculum",
    element: <Curriculum />,
  },
  {
    id: 21,
    path: "/courses/harmonise-course",
    element: <HarmoniseCourse />,
  },
  {
    id: 22,
    path: "/courses/late-course-registration",
    element: <LateRegistration />,
  },
  {
    id: 5,
    path: "/courses/register-courses-multiple",
    element: <RegisterCoursesMultiple />,
  },
  {
    id: 23,
    path: "/courses/view-score",
    element: <ViewScore />,
  },
  //***************** PROGRAM*************************//
  {
    id: 24,
    path: "/program/allocate",
    element: <Allocate />,
  },
  {
    id: 33,
    path: "/program/view-allocations",
    element: <ViewAllocation />,
  },
  {
    id: 29,
    path: "/program/view-all-programs",
    element: <ViewAllPrograms />,
  },
  {
    id: 25,
    path: "/program/view-course-registration",
    element: <ViewCourseReg />,
  },
  //***************** PAYMENTS *************************//
  {
    id: 26,
    path: "/payments/debtors",
    element: <Allocate />,
  },
  {
    id: 27,
    path: "/payments/fee-exemption",
    element: <FeeExcemption />,
  },
  {
    id: 28,
    path: "/payments/fee-exemption-freshmen",
    element: <ViewCourseReg />,
  },
  //***************** UTILITy *************************//
  {
    id: 28,
    path: "/utility/view-course-registration",
    element: <ViewCourseReg />,
  },
  {
    id: 32,
    path: "/utility/create-new-user",
    element: <NewUser />,
  },
];

export default routes;
