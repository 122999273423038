import React from "react";
import AllocateDetails from "../Components/program/AllocateDetails";
import AppLayout from "../Layouts/AppLayout";

const Allocate = () => {
    return (
        <AppLayout>
            <>
                <AllocateDetails />
            </>
        </AppLayout>
    );
};

export default Allocate;
