import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  allUserData: any;
  allLoading: boolean;
}

const initialState: Values = {
  allLoading: false,
  allUserData: null,
};

export const getAllUsers = createAsyncThunk("ViewAllUsersDat", async () => {
  return axiosInstance
    .get("/users")
    .then((res) => {
      //   console.log(res);
      return res.data;
    })
    .catch((err) => {
      return err;
    });
});

const allUserSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    clearAllUsers: (state) => {
      state.allLoading = false;
      state.allUserData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.allLoading = true;
      state.allUserData = null;
    });
    builder.addCase(getAllUsers.fulfilled, (state, { payload }) => {
      state.allLoading = false;
      state.allUserData = payload;
    });
    builder.addCase(getAllUsers.rejected, (state, { payload }) => {
      state.allLoading = false;
      state.allUserData = payload;
    });
  },
});

export const { clearAllUsers } = allUserSlice.actions;
export default allUserSlice.reducer;
