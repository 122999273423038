import React from "react";
import ViewAllProgs from "../Components/program/ViewAllProgs";
import AppLayout from "../Layouts/AppLayout";

const ViewAllPrograms = () => {
  return (
    <AppLayout>
      <>
        <ViewAllProgs />
      </>
    </AppLayout>
  );
};

export default ViewAllPrograms;
