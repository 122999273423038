import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
}

export interface AddStaffData {
  staffId: string;
  password: string;
}

const initialState: Values = {
  loading: false,
  data: null,
};

export const addStaff = createAsyncThunk(
  "addStaffData",
  async (data: AddStaffData) => {
    return axiosInstance
      .post("/staffs", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const addStaffSlice = createSlice({
  name: "addStaff",
  initialState,
  reducers: {
    clearStaffData: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addStaff.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(addStaff.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(addStaff.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
  },
});

// export const { clearStudentData } = studentSlice.actions;

export default addStaffSlice.reducer;
