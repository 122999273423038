import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  data: any;
  loading: boolean;
  addExemptData: any;
  addExemptLoading: boolean;
  delExemptData: any;
  delExemptLoading: boolean;
}

export interface ViewFeeExempt {
  session: string;
}

export interface AddFeeExempt {
  session: string;
  matricNumber: string;
  expiration: string;
  reason: string;
}

const initialState: Values = {
  loading: false,
  data: null,
  addExemptData: null,
  addExemptLoading: false,
  delExemptData: null,
  delExemptLoading: false,
};

export const ViewFeeExemptions = createAsyncThunk(
  "ViewFeeExcemptions",
  async (data: ViewFeeExempt) => {
    return axiosInstance
      .post(`/students/fee-exemption`, data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const AddFeeExemption = createAsyncThunk(
  "AddFeeExemption",
  async (data: AddFeeExempt) => {
    return axiosInstance
      .post("/students/add-fee-exemption", data)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const DeleteExemption = createAsyncThunk(
  "DeleteExemption",
  async (id: number) => {
    return axiosInstance
      .delete(`/students/fee-exemption/${id}`)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const FeeExemptionDataSlice = createSlice({
  name: "feeExcemptionData",
  initialState,
  reducers: {
    clearFeeExemptData: (state) => {
      state.loading = false;
      state.data = null;
      state.addExemptData = null;
      state.addExemptLoading = false;
      state.delExemptData = null;
      state.delExemptLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewFeeExemptions.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.addExemptData = null;
      state.addExemptLoading = false;
      state.delExemptData = null;
      state.delExemptLoading = false;
    });
    builder.addCase(ViewFeeExemptions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.addExemptData = null;
      state.addExemptLoading = false;
      state.delExemptData = null;
      state.delExemptLoading = false;
    });
    builder.addCase(ViewFeeExemptions.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.addExemptData = null;
      state.addExemptLoading = false;
      state.delExemptData = null;
      state.delExemptLoading = false;
    });
    builder.addCase(DeleteExemption.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.addExemptData = null;
      state.addExemptLoading = false;
      state.delExemptData = null;
      state.delExemptLoading = true;
    });
    builder.addCase(DeleteExemption.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addExemptData = null;
      state.addExemptLoading = false;
      state.delExemptData = payload;
      state.delExemptLoading = false;
    });
    builder.addCase(DeleteExemption.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addExemptData = null;
      state.addExemptLoading = false;
      state.delExemptData = payload;
      state.delExemptLoading = false;
    });
    builder.addCase(AddFeeExemption.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.addExemptData = null;
      state.addExemptLoading = true;
      state.delExemptData = null;
      state.delExemptLoading = false;
    });
    builder.addCase(AddFeeExemption.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addExemptData = payload;
      state.addExemptLoading = false;
      state.delExemptData = null;
      state.delExemptLoading = false;
    });
    builder.addCase(AddFeeExemption.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addExemptData = payload;
      state.addExemptLoading = false;
      state.delExemptData = null;
      state.delExemptLoading = false;
    });
  },
});

export const { clearFeeExemptData } = FeeExemptionDataSlice.actions;

export default FeeExemptionDataSlice.reducer;
