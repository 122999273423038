import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

interface Values {
  createData: any;
  createLoading: boolean;
}

export interface CreateUserData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  userTypeId: number;
  department: string;
  createdBy: string;
}

const initialState: Values = {
  createData: null,
  createLoading: false,
};

export const CreateUser = createAsyncThunk(
  "CreateUser",
  async (data: CreateUserData) => {
    return axiosInstance
      .post("/users", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const CreateUserSlice = createSlice({
  name: "createUser",
  initialState,
  reducers: {
    clearCreateUser: (state) => {
      state.createLoading = false;
      state.createData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CreateUser.pending, (state) => {
      state.createLoading = true;
      state.createData = null;
    });
    builder.addCase(CreateUser.fulfilled, (state, { payload }) => {
      state.createLoading = false;
      state.createData = payload;
    });
    builder.addCase(CreateUser.rejected, (state, { payload }) => {
      state.createLoading = false;
      state.createData = payload;
    });
  },
});

export const { clearCreateUser } = CreateUserSlice.actions;
export default CreateUserSlice.reducer;
