import React from "react";
import TransferStud from "../Components/TransferStud";
import AppLayout from "../Layouts/AppLayout";

const TransferStudents = () => {
    return (
        <AppLayout>
            <>
                <TransferStud />
            </>
        </AppLayout>
    );
};

export default TransferStudents;
