import React from "react";
import HarmoniseCourseDetails from "../Components/courses/HarmoniseCourseDetails";
import AppLayout from "../Layouts/AppLayout";

const HarmoniseCourse = () => {
    return (
        <AppLayout>
            <>
                <HarmoniseCourseDetails />
            </>
        </AppLayout>
    );
};

export default HarmoniseCourse;