import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../reusable/btnLoader";
import * as Yup from "yup";
import { AppDispatch, RootState } from "../../state/store";
import Sessions from "../reusable/sessions";
import { toast, ToastContainer } from "react-toastify";
import LinearDeterminate from "../reusable/LinearDeterminate";
import { ViewAllPrograms } from "../../state/programs/programs";
import {
  StaffRoleData,
  UpdateStaffRoleData,
  UpdateStaffRoles,
  ViewStaffRoles,
} from "../../state/staff/assignStaffRoles";
import DeleteModal from "../modals/DeleteModal";
import { Modal } from "react-bootstrap";

const AssignStaffRoleDetails = () => {
  const [delModal, setDelModal] = useState<any>();
  const [delModalShow, setDelModalShow] = useState<any>();
  const [levelInput, setLevelInput] = useState<number>(100);
  const [modalButton, setModalButton] = useState<any>();
  const [modalText, setModalText] = useState<any>();
  const [updateDataInput, setUpdateDataInput] = useState<any>();
  const [reloadInput, setReloadInput] = useState<any>();

  const dispatch = useDispatch<AppDispatch>();
  const {
    viewAllProg,
    assignStaffRoles: { loading, data, updateData, updateLoading },
  } = useSelector((state: RootState) => state);
  const initialValues: StaffRoleData = {
    programme: "",
    session: "",
  };
  const ValidationSchema = Yup.object().shape({
    programme: Yup.string().required("Programme is required"),
    session: Yup.string().required("Session is required"),
  });

  useEffect(() => {
    if (!viewAllProg?.data) {
      dispatch(ViewAllPrograms());
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
      }
      if (data?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (updateData) {
      if (updateData?.name === "AxiosError") {
        toast.error(
          `${
            updateData?.message
              ? updateData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (updateData?.success === true) {
        dispatch(ViewStaffRoles(reloadInput));
        toast.success(
          `${
            updateData?.message ? updateData?.message : "Role change successful"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (updateData?.success === false) {
        toast.error(
          `${
            updateData?.message
              ? updateData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data, updateData]);

  // console.log(loading);
  const handleSubmit = (values: StaffRoleData) => {
    // console.log(values);
    setReloadInput(values);
    dispatch(ViewStaffRoles(values));
  };

  const updateStaffRole = (data: UpdateStaffRoleData) => {
    // console.log(data);
    dispatch(UpdateStaffRoles(data));
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">
        {(viewAllProg?.loading || updateLoading) && <LinearDeterminate />}
      </div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Assign Staff Roles</h2>
                  <p className="mb-0">Assign roles to lecturers (PC | LA)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <hr className="mt-1" />

          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="programme"
                        type="programme"
                        //   defaultValue={initialprogramme}
                        className={
                          errors.programme && touched.programme
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select programme ...
                        </option>
                        {viewAllProg?.data?.data?.map(
                          (item: any, index: number) => (
                            <option value={item.programme} key={index}>
                              {item.programmeName}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        name="programme"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* REQUEST TABLE STARTS */}
        {data?.data && data?.data?.length === 0 ? (
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title py-2">No Lecturers</h2>
              </div>
            </div>
          </div>
        ) : (
          data?.data && (
            <div className="container my-4">
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h5 className="page-title py-2">All Roles</h5>
                  </div>
                </div>
              </div>
              {/* 1 response Unknown */}
              {/* <div className="card">
                <div className="">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>ID</th>
                        <th>College</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data &&
                        data?.data
                          ?.filter((item: any) => item.role === 1)
                          .map((item: any, index: number) => (
                            <tr className="" key={index}>
                              <td>{index + 1}</td>
                              <td className="text-muted">
                                {item?.title}. {item?.firstName}{" "}
                                {item?.lastName}
                              </td>
                              <td className="text-muted">{item?.id}</td>
                              <td className="text-muted">{item?.college}</td>
                              <td className="text-muted">
                                <button className="btn btn-sm btn-danger">
                                  Delete
                                </button>
                              </td>
                              <td className="text-muted">
                                <button className="btn btn-sm btn-info px-3">
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div> */}
              {/* 2 Programme Coordinators */}
              <div className="card my-4">
                <div className="">
                  <h5 className="text-center py-3">Program Coordinator</h5>
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>ID</th>
                        <th>College</th>
                        <th></th>
                        {/* <th className="w-1"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data &&
                        data?.data
                          ?.filter((item: any) => item.role === 2)
                          .map((item: any, index: number) => (
                            <tr className="" key={index}>
                              <td>{index + 1}</td>
                              <td className="text-muted">
                                {item?.title}. {item?.firstName}{" "}
                                {item?.lastName}
                              </td>
                              <td className="text-muted">{item?.id}</td>
                              <td className="text-muted">{item?.college}</td>
                              {/* <td className="text-muted">{item?.department}</td> */}
                              <td className="text-muted">
                                <span className="dropdown text-end col">
                                  <button
                                    className="btn btn-sm btn-secondary dropdown-toggle mb-1"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <ul className="dropdown-menu p-2 border-0">
                                    <li className="m-2 d-flex">
                                      <input
                                        type="number"
                                        value={levelInput}
                                        min="100"
                                        step="100"
                                        className="me-2"
                                        onChange={(e) => {
                                          const level = e.target.value;
                                          setLevelInput(+level);
                                        }}
                                      />{" "}
                                      <span className="fw-bold">Level</span>
                                    </li>
                                    <li className="m-2">
                                      <button
                                        className={
                                          "btn btn-sm btn-dark w-100 mx-auto py-1 px-2"
                                        }
                                        onClick={() => {
                                          setDelModal(item);
                                          setDelModalShow(true);
                                          setModalText({
                                            header: "Make Level Adviser",
                                            text: `<p>You are about to make  <b>${item?.title}. ${item?.firstName}
                                            ${item?.lastName} (${item?.id}) </b> the <b>${levelInput} Level Adviser</b> in the <b>${item?.programme}</b> programme, for the <b>${item?.session}</b> session. Are you sure???</p>`,
                                          });
                                          setModalButton({
                                            bg: "btn-dark",
                                            btnText: "Make LA",
                                          });
                                          setUpdateDataInput({
                                            session: item?.session,
                                            programme: item?.programme,
                                            role: 3,
                                            level: levelInput,
                                            staffId: item?.id,
                                          });
                                        }}
                                      >
                                        Make LA
                                      </button>
                                    </li>
                                    <li className="m-2">
                                      <button
                                        className={
                                          "btn btn-sm btn-danger w-100 mx-auto py-1 px-2"
                                        }
                                        onClick={() => {
                                          setDelModal(item);
                                          setDelModalShow(true);
                                          setModalText({
                                            header: "Remove PC",
                                            text: `<p>You are about to remove  <b>${item?.title}. ${item?.firstName}
                                            ${item?.lastName} (${item?.id})</b> as the programme coordinator of <b>${item?.programme}</b>, for the <b>${item?.session}</b> session. Are you sure???</p>`,
                                          });
                                          setModalButton({
                                            bg: "btn-danger",
                                            btnText: "Remove PC",
                                          });
                                          setUpdateDataInput({
                                            session: item?.session,
                                            programme: item?.programme,
                                            role: 1,
                                            level: 0,
                                            staffId: item?.id,
                                          });
                                        }}
                                      >
                                        Remove PC
                                      </button>
                                    </li>
                                  </ul>
                                </span>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* 3 Level Advisers */}
              <div className="card">
                <div className="">
                  <h5 className="text-center py-3">Level Advisers</h5>
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>ID</th>
                        <th>College</th>
                        <th>Level</th>
                        <th></th>
                        {/* <th className="w-1"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data &&
                        data?.data
                          ?.filter((item: any) => item.role === 3)
                          .map((item: any, index: number) => (
                            <tr className="" key={index}>
                              <td>{index + 1}</td>
                              <td className="text-muted">
                                {item?.title}. {item?.firstName}{" "}
                                {item?.lastName}
                              </td>
                              <td className="text-muted">{item?.id}</td>
                              <td className="text-muted">{item?.college}</td>
                              <td className="text-muted">{item?.level}</td>
                              {/* <td className="text-muted">{item?.department}</td> */}
                              <td className="text-muted">
                                <span className="dropdown text-end col">
                                  <button
                                    className="btn btn-sm btn-secondary dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <ul className="dropdown-menu p-2 border-0">
                                    <li className="m-2">
                                      <button
                                        className={
                                          "btn btn-sm btn-info w-100 mx-auto py-1 px-2"
                                        }
                                        onClick={() => {
                                          setDelModal(item);
                                          setDelModalShow(true);
                                          setModalText({
                                            header:
                                              "Make Programme Coordinator",
                                            text: `<p>You are about to make  <b>${item?.title}. ${item?.firstName}
                                            ${item?.lastName} (${item?.id})</b> a Programme Coordinator in the <b>${item?.programme}</b> programme, 
                                            for the <b>${item?.session}</b> session. Are you sure???</p>`,
                                          });
                                          setModalButton({
                                            bg: "btn-info",
                                            btnText: "Make PC",
                                          });
                                          setUpdateDataInput({
                                            session: item?.session,
                                            programme: item?.programme,
                                            role: 2,
                                            level: 0,
                                            staffId: item?.id,
                                          });
                                        }}
                                      >
                                        Make PC
                                      </button>
                                    </li>
                                    <li className="m-2">
                                      <button
                                        className={
                                          "btn btn-sm btn-danger w-100 mx-auto py-1 px-2"
                                        }
                                        onClick={() => {
                                          setDelModal(item);
                                          setDelModalShow(true);
                                          setModalText({
                                            header: "Remove Level Adviser",
                                            text: `<p>You are about to remove  <b>${item?.title}. ${item?.firstName}
                                            ${item?.lastName} (${item?.id})</b> as a Level Adviser in the <b>${item?.programme}</b> programme, 
                                            for the <b>${item?.session}</b> session. Are you sure???</p>`,
                                          });
                                          setModalButton({
                                            bg: "btn-danger",
                                            btnText: "Remove LA",
                                          });
                                          setUpdateDataInput({
                                            session: item?.session,
                                            programme: item?.programme,
                                            role: 1,
                                            level: 0,
                                            staffId: item?.id,
                                          });
                                        }}
                                      >
                                        Remove LA
                                      </button>
                                    </li>
                                  </ul>
                                </span>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* 4  Lecturers */}
              <div className="card my-4">
                <div className="">
                  <h5 className="text-center py-3">Lecturers</h5>
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>ID</th>
                        <th>College</th>
                        <th></th>
                        {/* <th className="w-1"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data &&
                        data?.data
                          ?.filter(
                            (item: any) => item.role === 4 || item.role === 1
                          )
                          .map((item: any, index: number) => (
                            <tr className="" key={index}>
                              <td>{index + 1}</td>
                              <td className="text-muted">
                                {item?.title}. {item?.firstName}{" "}
                                {item?.lastName}
                              </td>
                              <td className="text-muted">{item?.id}</td>
                              <td className="text-muted">{item?.college}</td>
                              {/* <td className="text-muted">{item?.department}</td> */}
                              <td className="text-muted">
                                <span className="dropdown text-end col">
                                  <button
                                    className="btn btn-sm btn-secondary dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <ul className="dropdown-menu p-2 border-0">
                                    <li className="m-2 d-flex">
                                      <input
                                        type="number"
                                        value={levelInput}
                                        min="100"
                                        step="100"
                                        className="me-2"
                                        onChange={(e) => {
                                          const level = e.target.value;
                                          setLevelInput(+level);
                                        }}
                                      />{" "}
                                      <span className="fw-bold">Level</span>
                                    </li>
                                    <li className="m-2">
                                      <button
                                        className={
                                          "btn btn-sm btn-info w-100 mx-auto py-1 px-2"
                                        }
                                        onClick={() => {
                                          setDelModal(item);
                                          setDelModalShow(true);
                                          setModalText({
                                            header:
                                              "Make Programme Coordinator",
                                            text: `<p>You are about to make  <b>${item?.title}. ${item?.firstName}
                                            ${item?.lastName} (${item?.id})</b> a Programme Coordinator in the <b>${item?.programme}</b> programme, 
                                            for the <b>${item?.session}</b> session. Are you sure???</p>`,
                                          });
                                          setModalButton({
                                            bg: "btn-info",
                                            btnText: "Make PC",
                                          });
                                          setUpdateDataInput({
                                            session: item?.session,
                                            programme: item?.programme,
                                            role: 2,
                                            level: 0,
                                            staffId: item?.id,
                                          });
                                        }}
                                      >
                                        Make PC
                                      </button>
                                    </li>
                                    <li className="m-2">
                                      <button
                                        className={
                                          "btn btn-sm btn-dark w-100 mx-auto py-1 px-2"
                                        }
                                        onClick={() => {
                                          setDelModal(item);
                                          setDelModalShow(true);
                                          setModalText({
                                            header: "Make Level Adviser",
                                            text: `<p>You are about to make  <b>${item?.title}. ${item?.firstName}
                                            ${item?.lastName} (${item?.id}) </b> the <b>${levelInput} Level adviser </b> in the <b>${item?.programme}</b> programme, for the <b>${item?.session}</b> session. Are you sure???</p>`,
                                          });
                                          setModalButton({
                                            bg: "btn-dark",
                                            btnText: "Make LA",
                                          });
                                          setUpdateDataInput({
                                            session: item?.session,
                                            programme: item?.programme,
                                            role: 3,
                                            level: levelInput,
                                            staffId: item?.id,
                                          });
                                        }}
                                      >
                                        Make LA
                                      </button>
                                    </li>
                                  </ul>
                                </span>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        )}
        {/* REQUEST TABLE ENDS */}

        {/* REMOVE MODAL STARTS*/}
        <div className="modal-blur ">
          <DeleteModal
            modal={delModal}
            show={delModalShow}
            onHide={() => setDelModalShow(false)}
            header={modalText?.header}
          >
            <Modal.Body className="p-4 pt-0 pb-3">
              {/* <p className="py-3">{modalText?.text} </p> */}
              <div dangerouslySetInnerHTML={{ __html: modalText?.text }} />
              <div className="modal-footer p-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setDelModalShow(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className={`btn ${
                    modalButton?.bg ? modalButton?.bg : "btn-danger"
                  }`}
                  onClick={() => {
                    updateStaffRole(updateDataInput);
                    setDelModalShow(false);
                  }}
                >
                  {modalButton?.btnText}
                </button>
              </div>
            </Modal.Body>
          </DeleteModal>
        </div>

        {/* REMOVE MODAL ENDS*/}
      </div>
    </div>
  );
};

export default AssignStaffRoleDetails;
