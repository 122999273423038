import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
}

export interface ViewStdRegType {}

const initialState: Values = {
  loading: false,
  data: null,
};

export const ViewStudentsRegisteredCourses = createAsyncThunk(
  "ViewStudentsRegisteredCourses",
  async (data: any) => {
    return axiosInstance
      .post("/programmes/registered-students", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const ProgramsDataSlice = createSlice({
  name: "ViewStudentsRegisteredCourses",
  initialState,
  reducers: {
    clearStudRegData: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewStudentsRegisteredCourses.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(
      ViewStudentsRegisteredCourses.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      }
    );
    builder.addCase(
      ViewStudentsRegisteredCourses.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      }
    );
  },
});

export const { clearStudRegData } = ProgramsDataSlice.actions;

export default ProgramsDataSlice.reducer;
