import React from "react";
import Search from "../Components/staff/Search";
import AppLayout from "../Layouts/AppLayout";

const SearchStaff = () => {
    return (
        <AppLayout>
            <>
                <Search />
            </>
        </AppLayout>
    );
};

export default SearchStaff;
