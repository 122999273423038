import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../state/auth";
import { AppDispatch, RootState } from "../state/store";
import { getCurrentUser } from "../state/user/user";
import LinearDeterminate from "./reusable/LinearDeterminate";

const NavBar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    user: { currLoading, currUserData },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!currUserData) {
      dispatch(getCurrentUser(localStorage.getItem("buAdmUsrId")));
    }
  }, []);

  const Logout = () => {
    localStorage.removeItem("buAdmToken");
    localStorage.removeItem("buAdmUsrId");
    dispatch(adminLogout());
    navigate("/auth/login");
  };

  return (
    <div className="page-wrapper">
      <div className="container">
        <div className="row g-2 align-items-center mt-4">
          {currLoading ? (
            <div className="fixed-top m-0">
              {<LinearDeterminate />}
            </div>
          ) : (
            <div className="col fw-bold">
              {`${currUserData?.data?.firstName} ${currUserData?.data?.lastName}`}
              <div className="page-pretitle m-0">
                {currUserData?.data?.userType}
              </div>
              {/* <h2 className="page-title">Welcome Admin</h2> */}
            </div>
          )}
          <div className="col-12 col-md-auto ms-auto d-print-none">
            <div className="btn-list">
              <button
                //   href="/"
                className="btn btn-primary d-none d-sm-inline-block"
                //   data-bs-toggle="modal"
                //   data-bs-target="#modal-report"
                onClick={Logout}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-arrow-bar-left"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="4" y1="12" x2="14" y2="12"></line>
                  <line x1="4" y1="12" x2="8" y2="16"></line>
                  <line x1="4" y1="12" x2="8" y2="8"></line>
                  <line x1="20" y1="4" x2="20" y2="20"></line>
                </svg>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
