import React from "react";

const TransferStud = () => {
    return (
        <div>
            <div className="page-wrapper">
                <div>
                    <div className="page-header d-print-none">
                        <div className="container-xl">
                            <div className="row g-2 align-items-center">
                                <div className="col">
                                    Name of Admin
                                    <div className="page-pretitle">Role</div>
                                    <h2 className="page-title">
                                        Transfer Students
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <p className="mb-0">
                        View all transfer students and generate matric number
                        for new students
                    </p>
                    <hr className="mt-1" />
                </div>
            </div>
        </div>
    );
};

export default TransferStud;
