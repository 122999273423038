import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import BtnLoader from "../reusable/btnLoader";
import Sessions from "../reusable/sessions";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { ViewAllPrograms } from "../../state/programs/programs";
import {
  AddStaffData,
  AddStaffMapping,
  DelandToggleStaffData,
  DelStaffMapping,
  ToggleStaffMapping,
  ViewStaffData,
  ViewStaffMappings,
} from "../../state/staff/staffCourseMap";
import DeleteModal from "../modals/DeleteModal";
import { Modal } from "react-bootstrap";
import { ViewAllStaffNonPaginated } from "../../state/staff/allStaff";
import LinearDeterminate from "../reusable/LinearDeterminate";

const CourseMapDetails = () => {
  const [modal, setModal] = useState<any>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [LecturerDataModal, setLecturerDataModal] = useState<any>();
  const [deleteModal, setDeleteModal] = useState<any>();
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [staffData, setStaffData] = useState<[]>();
  const [inputFormData, setInputFormData] = useState<ViewStaffData>({
    programme: "",
    session: "",
  });
  const dispatch = useDispatch<AppDispatch>();
  const {
    viewAllProg,
    staffCourseMap: {
      data,
      loading,
      addData,
      addLoading,
      toggleData,
      toggleLoading,
      delData,
      delLoading,
    },
    allStaff,
  } = useSelector((state: RootState) => state);

  const initialValues: ViewStaffData = {
    programme: "",
    session: "",
  };
  const ValidationSchema = Yup.object().shape({
    programme: Yup.string().required("Programme is required"),
    session: Yup.string().required("Session is required"),
  });
  const AddRoleValidationSchema = Yup.object().shape({
    session: Yup.string(),
    semester: Yup.string(),
    courseId: Yup.string(),
    staffId: Yup.string().required("Session is required"),
    leadRole: Yup.boolean().required("Role is required"),
  });

  useEffect(() => {
    if (!viewAllProg?.data) {
      dispatch(ViewAllPrograms());
    }
    if (!allStaff?.nonPagdata) {
      dispatch(ViewAllStaffNonPaginated());
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setStaffData(data?.data);
      }
      if (data?.success === false) {
        toast.error("Something went wrong please try again", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (addData) {
      if (addData?.name === "AxiosError") {
        toast.error(
          `${
            addData?.message
              ? addData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (addData?.success === true) {
        toast.success(
          `New Lecturer Added successfully for ${addData?.data.courseCode} in ${addData?.data.session} academic session.`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch(ViewStaffMappings(inputFormData));
        setModalShow(false);
      }
      if (addData?.success === false) {
        toast.error("Something went wrong please try again", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (toggleData) {
      if (toggleData?.name === "AxiosError") {
        toast.error(
          `${
            toggleData?.message
              ? toggleData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (toggleData?.success === true) {
        toast.success(
          `Lecturer role changed for ${toggleData?.data.courseCode} in ${toggleData?.data.session} academic session.`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch(ViewStaffMappings(inputFormData));
      }
      if (toggleData?.success === false) {
        toggleData("Something went wrong please try again", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (delData) {
      if (delData?.name === "AxiosError") {
        toast.error(
          `${
            delData?.message
              ? delData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (delData?.success === true) {
        toast.success(
          `Lecturer removed successfully, for ${delData?.data.courseCode} in ${delData?.data.session} academic session.`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch(ViewStaffMappings(inputFormData));
      }
      if (delData?.success === false) {
        toast.error("Something went wrong please try again", {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [data, addData, toggleData, delData]);

  const handleSubmit = (values: ViewStaffData) => {
    // console.log(values);
    dispatch(ViewStaffMappings(values));
    setInputFormData(values);
  };

  const handleAddRole = ({
    session,
    semester,
    courseId,
    staffId,
    leadRole,
  }: any) => {
    const data: AddStaffData = {
      session,
      semester,
      courseId,
      staffId,
      leadRole:
        leadRole === "true" ? true : leadRole === "false" ? false : false,
    };
    // console.log(data);
    dispatch(AddStaffMapping(data));
    // setModalShow(false);
  };

  const handleToggleRole = (
    session: string,
    semester: string,
    courseId: string,
    staffId: string
  ) => {
    const data: DelandToggleStaffData = {
      session,
      semester:
        semester === "First"
          ? 0
          : semester === "Second"
          ? 1
          : semester === "ERP"
          ? 3
          : 0,
      courseId,
      staffId,
    };

    dispatch(ToggleStaffMapping(data));
  };

  const handleDeleteRole = (
    session: string,
    semester: string,
    courseId: string,
    staffId: string
  ) => {
    const data: DelandToggleStaffData = {
      session,
      semester:
        semester === "First"
          ? 0
          : semester === "Second"
          ? 1
          : semester === "ERP"
          ? 3
          : 0,
      courseId,
      staffId,
    };
    // console.log(session, semester, courseId, staffId);
    // console.log(data);
    dispatch(DelStaffMapping(data));
    setDeleteModalShow(false);
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">
        {allStaff.nonPagloading && <LinearDeterminate />}
        {toggleLoading && <LinearDeterminate />}
        {delLoading && <LinearDeterminate />}
      </div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title"> Staff Course Mapping</h2>
                  <p className="mb-0">Assign courses to lecturers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="programme"
                        type="programme"
                        //   defaultValue={initialprogramme}
                        className={
                          errors.programme && touched.programme
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select programme ...
                        </option>
                        {viewAllProg?.data?.data?.map(
                          (item: any, index: number) => (
                            <option value={item.programme} key={index}>
                              {item.programmeName}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        name="programme"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>

          {/* REQUEST RESPONSE TABLE STARTS */}
          <div>
            {staffData && staffData?.length === 0 ? (
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h2 className="page-title py-2">No Results Found</h2>
                  </div>
                </div>
              </div>
            ) : (
              staffData && (
                <div className="container my-4">
                  <div className="container-xl">
                    <div className="row g-2 align-items-center">
                      <div className="col">
                        <h5 className="page-title py-2">Search Results</h5>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-vcenter card-table table-striped">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Course Code</th>
                            <th className="col-2">Course Title</th>
                            <th>Course Type</th>
                            <th>Unit</th>
                            <th>Semester</th>
                            <th>Level</th>
                            <th>Lecturers $ Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {staffData &&
                            staffData
                              .slice()
                              .sort((a: any, b: any) =>
                                a.courseCode.localeCompare(b.courseCode)
                              )
                              ?.map((item: any, index: number) => (
                                <tr className="" key={item?.id}>
                                  <td>{index + 1}</td>
                                  <td>{item?.courseCode}</td>
                                  <td className="text-muted">
                                    {item?.courseTitle}
                                  </td>
                                  <td className="text-muted">
                                    {item?.courseType}
                                  </td>
                                  <td className="text-muted">{item?.unit}</td>
                                  <td className="text-muted">
                                    {item?.semesterName}
                                  </td>
                                  <td className="text-muted">{item?.level}</td>

                                  <td className="text-muted pe-5">
                                    {item?.lecturers.map(
                                      (lecturer: any, index: number) =>
                                        lecturer?.status === 1 ? (
                                          <span
                                            className="fw-bold my-1 row lecturer__lead"
                                            key={index}
                                          >
                                            <span className="col-8">
                                              {lecturer.title}{" "}
                                              {lecturer.firstName}{" "}
                                              {lecturer.lastName}{" "}
                                              <span className="fw-bold lecturer__lead--label px-3 py-1">
                                                Lead
                                              </span>
                                            </span>

                                            <span className="dropdown text-end col">
                                              <button
                                                className="btn btn-sm btn-secondary dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                Action
                                              </button>
                                              <ul className="dropdown-menu p-2 border-0">
                                                <li className="m-2">
                                                  <button
                                                    className={
                                                      "btn btn-sm btn-dark w-100 mx-auto py-1 px-2"
                                                    }
                                                    onClick={() =>
                                                      handleToggleRole(
                                                        item?.session,
                                                        item?.semesterName,
                                                        item?.id,
                                                        lecturer?.id
                                                      )
                                                    }
                                                  >
                                                    Toggle role
                                                  </button>
                                                </li>
                                                <li className="m-2">
                                                  <button
                                                    className={
                                                      "btn btn-sm btn-danger w-100 mx-auto py-1 px-2"
                                                    }
                                                    onClick={() => {
                                                      setDeleteModal(item);
                                                      setLecturerDataModal(
                                                        lecturer
                                                      );
                                                      setDeleteModalShow(true);
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                </li>
                                              </ul>
                                            </span>
                                          </span>
                                        ) : (
                                          <span
                                            className=" my-1 row"
                                            key={lecturer.id}
                                          >
                                            <span className="col-8">
                                              {lecturer.title}{" "}
                                              {lecturer.firstName}{" "}
                                              {lecturer.lastName}{" "}
                                            </span>
                                            <span className="dropdown text-end col">
                                              <button
                                                className="btn btn-sm btn-secondary dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                Action
                                              </button>
                                              <ul className="dropdown-menu p-2 border-0">
                                                <li className="m-2">
                                                  <button
                                                    className={
                                                      "btn btn-sm btn-dark w-100 mx-auto py-1 px-2"
                                                    }
                                                    onClick={() =>
                                                      handleToggleRole(
                                                        item?.session,
                                                        item?.semesterName,
                                                        item?.id,
                                                        lecturer?.id
                                                      )
                                                    }
                                                  >
                                                    Toggle role
                                                  </button>
                                                </li>
                                                <li className="m-2">
                                                  <button
                                                    className={
                                                      "btn btn-sm btn-danger w-100 mx-auto py-1 px-2"
                                                    }
                                                    onClick={() => {
                                                      setDeleteModal(item);
                                                      setLecturerDataModal(
                                                        lecturer
                                                      );
                                                      setDeleteModalShow(true);
                                                    }}
                                                  >
                                                    Delete
                                                  </button>
                                                </li>
                                              </ul>
                                            </span>
                                          </span>
                                        )
                                    )}
                                  </td>
                                  <td className="text-muted ">
                                    <button
                                      className={
                                        "btn btn-sm btn-success mx-auto py-1 px-2 mt-0"
                                      }
                                      onClick={() => {
                                        setModal(item);
                                        setModalShow(true);
                                      }}
                                    >
                                      Add Lecturer
                                    </button>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          {/* REQUEST RESPONSE TABLE ENDS */}

          {/* ADD LECTURER MODAL STARTS */}
          <div className="modal-blur ">
            <DeleteModal
              modal={modal}
              show={modalShow}
              onHide={() => setModalShow(false)}
              size={"modal-md"}
              header={`Assign Lecturer to ${modal?.courseCode}`}
            >
              <Modal.Body className="p-4 pt-0 pb-2">
                <Formik
                  initialValues={{
                    session: modal?.session,
                    semester:
                      modal?.semesterName === "First"
                        ? 0
                        : modal?.semesterName === "Second"
                        ? 1
                        : modal?.semesterName === "ERP"
                        ? 3
                        : null,
                    courseId: modal?.id,
                    staffId: "",
                    leadRole: false,
                  }}
                  validationSchema={AddRoleValidationSchema}
                  onSubmit={handleAddRole}
                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-12 my-2">
                            <label htmlFor="Session" className="fw-bold">
                              Staff
                            </label>
                            <Field
                              as="select"
                              name="staffId"
                              type="staffId"
                              //   defaultValue={initialstaffId}
                              className={
                                errors.staffId && touched.staffId
                                  ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                                  : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                              }
                            >
                              <option value="" disabled>
                                select staff ...
                              </option>
                              {allStaff?.nonPagdata?.data.itemList
                                .slice()
                                .sort((a: any, b: any) => {
                                  if (a.departmentCode === null) {
                                    return +1;
                                  }
                                  return a.departmentCode.localeCompare(
                                    b.departmentCode
                                  );
                                })
                                ?.map((item: any, index: any) => (
                                  <option value={item.staffId} key={item.id}>
                                    {item.departmentCode}-{item.faculty} |{" "}
                                    {item.firstName} {item.lastName} (
                                    {item.staffId})
                                  </option>
                                ))}
                            </Field>
                            <ErrorMessage
                              name="staffId"
                              component="div"
                              className="error"
                            />
                          </div>

                          <div className="col-12 my-2">
                            <label htmlFor="Session" className="fw-bold">
                              Role
                            </label>
                            <Field
                              as="select"
                              name="leadRole"
                              type="leadRole"
                              //   defaultValue={initialleadRole}
                              className={
                                errors.leadRole && touched.leadRole
                                  ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                                  : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                              }
                            >
                              <option value="" disabled>
                                select role ...
                              </option>

                              <option value="true">Lead</option>
                              <option value="false">Assisting</option>
                            </Field>
                            <ErrorMessage
                              name="leadRole"
                              component="div"
                              className="error"
                            />
                          </div>

                          <div className="col-12 my-2">
                            {addLoading ? (
                              <BtnLoader />
                            ) : (
                              <button
                                type="submit"
                                className={
                                  !(dirty && isValid)
                                    ? "disabled-btn btn btn-success w-100 mx-auto py-3 px-3"
                                    : "btn btn-success w-100 mx-auto py-3 px-3"
                                }
                                disabled={!(dirty && isValid)}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <div className="modal-footer"></div>
              </Modal.Body>
            </DeleteModal>
          </div>
          {/* ADD LECTURER MODAL ENDS*/}

          {/* DELETE MODAL STARTS */}
          <div className="modal-blur ">
            <DeleteModal
              modal={deleteModal}
              show={deleteModalShow}
              onHide={() => setDeleteModalShow(false)}
              size={"modal-md"}
              header={`Delete Lecturer for ${deleteModal?.courseCode}`}
            >
              <Modal.Body className="p-4 pt-0 pb-4">
                <p className="py-2">
                  You are about to remove{" "}
                  <strong>
                    {LecturerDataModal?.title} {LecturerDataModal?.firstName}{" "}
                    {LecturerDataModal?.lastName}{" "}
                  </strong>
                  from <strong>{deleteModal?.courseCode}</strong> in the{" "}
                  <strong>{deleteModal?.session}</strong> academic session. Are
                  you sure you want to delete ???
                </p>
                <div className="modal-footer pb-0 mb-0">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setDeleteModalShow(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      handleDeleteRole(
                        deleteModal?.session,
                        deleteModal?.semesterName,
                        deleteModal?.id,
                        LecturerDataModal?.id
                      );
                    }}
                  >
                    Delete
                  </button>
                </div>
              </Modal.Body>
            </DeleteModal>
          </div>
          {/* DELETE MODAL ENDS */}
        </div>
      </div>
    </div>
  );
};

export default CourseMapDetails;
