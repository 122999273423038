import React from "react";
import SearchStudMatric from "../Components/SearchStudMatric";
import AppLayout from "../Layouts/AppLayout";

const SearchStudentMatric = () => {
    return (
        <AppLayout>
            <>
                <SearchStudMatric />
            </>
        </AppLayout>
    );
};

export default SearchStudentMatric;
