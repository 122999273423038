import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
}

const initialState: Values = {
  loading: false,
  data: null,
};

export const ViewAllPrograms = createAsyncThunk("ViewAllPrograms", async () => {
  return axiosInstance
    .get("/programmes")
    .then((res) => {
      //   console.log(res);
      return res.data;
    })
    .catch((err) => {
      return err;
    });
});

const ProgramsDataSlice = createSlice({
  name: "allPrograms",
  initialState,
  reducers: {
    clearProgramsData: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewAllPrograms.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(ViewAllPrograms.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(ViewAllPrograms.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
  },
});

export const { clearProgramsData } = ProgramsDataSlice.actions;

export default ProgramsDataSlice.reducer;
