import React, { useState } from "react";
import { useForm } from "react-hook-form";

const CourseRegPrints = () => {
    const [result, setResult] = useState();
    const { register, handleSubmit, reset } = useForm();
    return (
        <div>
            <div className="page-wrapper">
                <div>
                    <div className="page-header d-print-none">
                        <div className="container-xl">
                            <div className="row g-2 align-items-center">
                                <div className="col">
                                    Name of Admin
                                    <div className="page-pretitle">Role</div>
                                    <h2 className="page-title">
                                        Course Registration (Print)
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <p className="mb-0">
                        Print out the course registration for students
                    </p>
                    <hr className="mt-1" />
                    <div className="row">
                        <div className="col-md-4">
                            <select
                                id="volunteer__project"
                                name="volunteer__project"
                                className="p-3 form-select benefits__form--input shadow-none"
                                defaultValue="DEFAULT"
                                required
                            >
                                <option value="DEFAULT" disabled>
                                    --select programme--
                                </option>
                                <option value="">Accountnig</option>
                                <option value="">Agriculture</option>
                                <option value="">Anatomy</option>
                                <option value="">Anatmoy Intercalated</option>
                                <option value="">Architecture</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select
                                id="volunteer__project"
                                name="volunteer__project"
                                className="p-3 form-select benefits__form--input shadow-none"
                                defaultValue="DEFAULT"
                                required
                            >
                                <option value="DEFAULT" disabled>
                                    --select session--
                                </option>
                                <option value="">2019/2020</option>
                                <option value="">2020/2021</option>
                                <option value="">2021/2022</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select
                                id="volunteer__project"
                                name="volunteer__project"
                                className="p-3 form-select benefits__form--input shadow-none"
                                defaultValue="DEFAULT"
                                required
                            >
                                <option value="DEFAULT" disabled>
                                    --select semester--
                                </option>
                                <option value="">First</option>
                                <option value="">Second</option>
                                <option value="">ERP</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select
                                id="volunteer__project"
                                name="volunteer__project"
                                className="p-3 form-select benefits__form--input shadow-none"
                                defaultValue="DEFAULT"
                                required
                            >
                                <option value="DEFAULT" disabled>
                                    --select Course--
                                </option>
                                <option value="">BOW 101</option>
                                <option value="">BOW 102</option>
                                <option value="">BWN 201</option>
                                <option value="">GST 201</option>
                                <option value="">GNS 231</option>
                                <option value="">GNS 232</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <button
                                type="submit"
                                className="btn btn-primary w-100 mx-auto py-3 px-3"
                            >
                                Go
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container">{result}</div>
            </div>
        </div>
    );
};

export default CourseRegPrints;
