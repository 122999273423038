import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
  harmonisedLoading: boolean;
  harmonisedData: any;
}

const initialState: Values = {
  loading: false,
  data: null,
  harmonisedLoading: false,
  harmonisedData: null,
};

export interface HarmoniseCourseData {
  session: string;
  oldCourseCode: string;
  newCourseCodes: string[];
}

export const ViewHarmonisedCourses = createAsyncThunk(
  "ViewHarmonisedCourses",
  async () => {
    return axiosInstance
      .get("/courses/replacement")
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const AddReplacementCourse = createAsyncThunk(
  "AddReplacementCourse",
  async (data: HarmoniseCourseData) => {
    return axiosInstance
      .post("/courses/replacement", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const HarmoniseCourseSlice = createSlice({
  name: "HarmoniseCourse",
  initialState,
  reducers: {
    clearHarmoniseData: (state) => {
      state.loading = false;
      state.data = null;
      state.harmonisedLoading = false;
      state.harmonisedData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewHarmonisedCourses.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.harmonisedLoading = false;
      state.harmonisedData = null;
    });
    builder.addCase(ViewHarmonisedCourses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.harmonisedLoading = false;
      state.harmonisedData = null;
    });
    // builder.addCase(ViewHarmonisedCourses.rejected, (state, { payload }) => {
    //   state.loading = false;
    //   state.data = payload;
    //   state.harmonisedLoading = false;
    //   state.harmonisedData = null;
    // });
    builder.addCase(AddReplacementCourse.pending, (state) => {
      state.loading = false;
      //   state.data = null;
      state.harmonisedLoading = true;
      state.harmonisedData = null;
    });
    builder.addCase(AddReplacementCourse.fulfilled, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      state.harmonisedLoading = false;
      state.harmonisedData = payload;
    });
    //   builder.addCase(ViewHarmonisedCourses.rejected, (state, { payload }) => {
    //     state.loading = false;
    //     state.data = payload;
    //     state.harmonisedLoading = false;
    //     state.harmonisedData = null;
    //   });
  },
});

export const { clearHarmoniseData } = HarmoniseCourseSlice.actions;

export default HarmoniseCourseSlice.reducer;
