import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
}
export interface LateCourseRegData {
  matricNumber: "string";
  session: "string";
}

const initialState: Values = {
  loading: false,
  data: null,
};
export const LateCourseReg = createAsyncThunk(
  "LateCourseRegister",
  async (data: LateCourseRegData) => {
    return axiosInstance
      .post("/students/late-registration", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
const LateCourseRegistrationSlice = createSlice({
  name: "lateCourseReg",
  initialState,
  reducers: {
    clearLateCourseReg: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LateCourseReg.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(LateCourseReg.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(LateCourseReg.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
  },
});

export const { clearLateCourseReg } = LateCourseRegistrationSlice.actions;

export default LateCourseRegistrationSlice.reducer;
