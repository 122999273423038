import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Suspend {
  defaultData: any;
  data: any;
  unsuspData: any;
  loading: boolean;
  defaultLoading: boolean;
}

export interface SuspendData {
  matricNumber: string;
  suspend: boolean;
}

const initialState: Suspend = {
  defaultData: null,
  data: null,
  unsuspData: null,
  loading: false,
  defaultLoading: false,
};
export const getSuspendedStudents = createAsyncThunk(
  "GetSuspendedStudent",
  async () => {
    return axiosInstance
      .get("/students/suspend")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const suspendStudent = createAsyncThunk(
  "SuspendStudent",
  async (data: SuspendData) => {
    return axiosInstance
      .post("/students/suspend", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const unsuspendStudent = createAsyncThunk(
  "UnsuspendStudent",
  async (data: SuspendData) => {
    return axiosInstance
      .post("/students/suspend", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const SuspendStudSlice = createSlice({
  name: "suspendStud",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuspendedStudents.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.defaultData = null;
      state.defaultLoading = true;
      state.unsuspData = null;
    });
    builder.addCase(getSuspendedStudents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.defaultData = payload;
      state.defaultLoading = false;
      state.unsuspData = null;
    });
    builder.addCase(getSuspendedStudents.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.defaultData = payload;
      state.defaultLoading = false;
      state.unsuspData = null;
    });
    builder.addCase(suspendStudent.pending, (state) => {
      state.loading = true;
      //   state.data = null;
      //   state.defaultData = null;
      state.defaultLoading = false;
    });
    builder.addCase(suspendStudent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.defaultData = null;
      state.defaultLoading = false;
      state.unsuspData = null;

    });
    builder.addCase(suspendStudent.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.defaultData = null;
      state.defaultLoading = false;
      state.unsuspData = null;

    });
    builder.addCase(unsuspendStudent.pending, (state) => {
      state.loading = false;
      state.defaultLoading = true;
    });
    builder.addCase(unsuspendStudent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.defaultData = null;
      state.defaultLoading = false;
      state.unsuspData = payload;
    });
    builder.addCase(unsuspendStudent.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.defaultData = null;
      state.defaultLoading = false;
      state.unsuspData = payload;
    });
  },
});

export default SuspendStudSlice.reducer;
