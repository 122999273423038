import React from "react";
import ViewAllocationDetails from "../Components/program/ViewAllocationDetails";
import AppLayout from "../Layouts/AppLayout";

const ViewAllocations = () => {
  return (
    <AppLayout>
      <>
        <ViewAllocationDetails />
      </>
    </AppLayout>
  );
};

export default ViewAllocations;
