import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
    loading: boolean;
    data: any;
  }
  
  export interface AddCoursesData {
    programme: string,
    courseCode: string,
    level: string,
    courseType: string,
    session: string,
    semesterName: string,

  }
  
  const initialState: Values = {
    loading: false,
    data: null,
  };
  
  export const AddCourses = createAsyncThunk(
    "addCourses",
    async (data: AddCoursesData) => {
      return axiosInstance
        .post("/courses", data)
        .then((res) => {
        //   console.log(res);
          return res.data;
        })
        .catch((err) => {
          return err;
        });
    }
);

const AddCoursesSlice = createSlice({
    name: "AddCourses",
    initialState,
    reducers: {
      clearAddCourses: (state) => {
        state.loading = false;
        state.data = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(AddCourses.pending, (state) => {
        state.loading = true;
        state.data = null;
      });
      builder.addCase(AddCourses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      });
      builder.addCase(AddCourses.rejected, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      });
    },
  });
  
  export const { clearAddCourses } = AddCoursesSlice.actions;
  
  export default AddCoursesSlice.reducer;
  

