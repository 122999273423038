import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface StateValues {
  loading: boolean;
  data: any;
}

export interface studRegValues {
  matricNumbers: [];
  session: string;
  semester: 0;
  courseCodes: [];
}

const initialState: StateValues = {
  loading: false,
  data: null,
};

export const RegisterStudentCourses = createAsyncThunk(
  "RegisterStudentCourses",
  async (data: studRegValues) => {
    return axiosInstance
      .post(`/students/courses`, data)
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const RegStudCourseMultipleSlice = createSlice({
  name: "RegStudMultiple",
  initialState,
  reducers: {
    clearRegStudCourseMultipleData: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RegisterStudentCourses.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(RegisterStudentCourses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(RegisterStudentCourses.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
  },
});

export const { clearRegStudCourseMultipleData } =
  RegStudCourseMultipleSlice.actions;

export default RegStudCourseMultipleSlice.reducer;
