import React from "react";
import Check from "../Components/Check";
import AppLayout from "../Layouts/AppLayout";

const CheckUpdate = () => {
    return (
        <AppLayout>
            <>
                {/* <Sidebar /> */}
                <Check />
            </>
        </AppLayout>
    );
};

export default CheckUpdate;
