import { ErrorMessage, Field, Form, Formik } from "formik";
import Sessions from "../reusable/sessions";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import LinearDeterminate from "../reusable/LinearDeterminate";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import {
  AllocateStudentData,
  AllocateStudents,
  DeleteCorrespondAllocation,
} from "../../state/programs/allocate";
import BtnLoader from "../reusable/btnLoader";
import DeleteModal from "../modals/DeleteModal";
import { Modal } from "react-bootstrap";
import { ViewAllocationPrograms } from "../../state/programs/allocationPrograms";

const AllocateDetails = () => {
  const [allocateData, setAllocateData] = useState<[]>();
  //   const [formInputs, setFormInputs] = useState<any>();
  const [modal, setModal] = useState<any>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const {
    viewAllocationProg,
    allocateStud: { data, loading, deleteData, deleteLoading },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch<AppDispatch>();
  const initialValues = {
    matricNumbers: "",
    programme: "",
    session: "",
    semester: "",
    level: "",
    spill: "0",
  };

  const SignInSchema = Yup.object().shape({
    matricNumbers: Yup.string().required(
      "At least one matric number is required"
    ),
    programme: Yup.string().required("Programme is required"),
    session: Yup.string().required("Session is required"),
    semester: Yup.string().required("Semester is required"),
    level: Yup.string().required("Level is required"),
  });

  useEffect(() => {
    if (!viewAllocationProg?.data) {
      dispatch(ViewAllocationPrograms());
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setAllocateData(data?.data);
        toast.success(
          `${
            data?.data?.message ? data?.data?.message : "Allocation successful"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? `${data?.message?.matricNumbers[0]} 
                ${data?.message?.level[0]} 
                ${data?.message?.semester[0]} ${data?.message?.session[0]} ${data?.message?.spill[0]}`
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
    if (deleteData) {
      if (deleteData?.name === "AxiosError") {
        toast.error(
          `${
            deleteData?.message
              ? deleteData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (deleteData?.success === true) {
        // dispatch(AllocateStudents(formInputs));
        toast.success(
          `${
            deleteData?.message
              ? deleteData?.message
              : "Allocation deleted successfully"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (deleteData?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [data, deleteData]);

  const handleSubmit = ({
    matricNumbers,
    programme,
    session,
    semester,
    level,
    spill,
  }: AllocateStudentData) => {
    const data = {
      matricNumbers: matricNumbers.replace(/\s/g, "").split(","),
      programme,
      session,
      semester,
      level,
      spill: spill.length === 0 ? "0" : "1",
    };
    // setFormInputs(data);
    dispatch(AllocateStudents(data));
  };
  const handleDelete = (id: number) => {
    dispatch(DeleteCorrespondAllocation(id));
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">
        {viewAllocationProg?.loading && <LinearDeterminate />}
        {deleteLoading && <LinearDeterminate />}
      </div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Allocate</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            Assign a student to a department for the session. This is useful for
            students that changed their programme or transfered to a different
            programme
          </p>
          <hr className="mt-1" />

          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-12 my-2">
                      <Field
                        as="textarea"
                        name="matricNumbers"
                        id="matricNumbers"
                        style={{
                          height: "200px",
                        }}
                        placeholder="Matric number(s): BU19PST2011, BU19PST2012, BU19PST2012"
                        autoComplete="off"
                        className={
                          errors.matricNumbers && touched.matricNumbers
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="matricNumbers"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="programme"
                        type="programme"
                        //   defaultValue={initialprogramme}
                        className={
                          errors.programme && touched.programme
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select programme ...
                        </option>
                        {viewAllocationProg?.data?.data?.map(
                          (item: any, index: number) => (
                            <option value={item.programme} key={index}>
                              {item.programmeName}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        name="programme"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-2 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-2 my-2">
                      <Field
                        as="select"
                        name="semester"
                        type="semester"
                        //   defaultValue={initialsemester}
                        className={
                          errors.semester && touched.semester
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select semester ...
                        </option>
                        <option value="0">First</option>
                        <option value="1">Second</option>
                        <option value="2">ERP</option>
                      </Field>
                      <ErrorMessage
                        name="semester"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-2 my-2">
                      <Field
                        as="select"
                        name="level"
                        type="level"
                        //   defaultValue={initiallevel}
                        className={
                          errors.level && touched.level
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select level...
                        </option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="300">300</option>
                        <option value="400">400</option>
                        <option value="500">500</option>
                        <option value="600">600</option>
                        <option value="700">700</option>
                      </Field>
                      <ErrorMessage
                        name="level"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-2 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Allocate
                        </button>
                      )}
                    </div>
                    <div className="col-md-2 my-2">
                      <Field type="checkbox" name="spill" value="1" />{" "}
                      <span className="fw-bold">Spill Over</span>
                      <ErrorMessage
                        name="spill-over"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div>
            {allocateData && allocateData?.length === 0 ? (
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h2 className="page-title py-2">No Results Found</h2>
                  </div>
                </div>
              </div>
            ) : (
              allocateData && (
                <div className="container my-4">
                  <div className="container-xl">
                    <div className="row g-2 align-items-center">
                      <div className="col">
                        <h5 className="page-title py-2">
                          Corresponding Allocations
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-vcenter card-table table-striped">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Name</th>
                            <th>Matric Number</th>
                            <th>College</th>
                            <th>Programme</th>
                            <th>Session</th>
                            <th>Semester</th>
                            <th>Level</th>
                            <th>Spill over</th>
                            {/* <th className="w-1"></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {allocateData &&
                            allocateData.map((item: any, index: number) => (
                              <tr className="" key={item?.id}>
                                <td>{index + 1}</td>
                                <td className="text-muted">
                                  {item?.firstName} {item?.lastName}
                                </td>
                                <td className="text-muted">
                                  {item?.matricNumber}
                                </td>
                                <td className="text-muted">{item?.college}</td>
                                <td className="text-muted">
                                  {item?.programme}
                                </td>
                                <td className="text-muted">{item?.session}</td>
                                <td className="text-muted">{item?.status}</td>
                                <td className="text-muted">{item?.level}</td>
                                <td className="text-muted">
                                  {item?.spill === 1 ? "Yes" : "No"}
                                </td>
                                <td className="text-muted">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() => {
                                      setModalShow(true);
                                      setModal(item);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="modal-blur ">
              <DeleteModal
                modal={modal}
                show={modalShow}
                onHide={() => setModalShow(false)}
              >
                <Modal.Body className="p-4">
                  <h6 className="py-3">
                    Are you sure you want to delete allocation???
                  </h6>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setModalShow(false)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        handleDelete(modal?.id);
                        setModalShow(false);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </Modal.Body>
              </DeleteModal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllocateDetails;
