import React from "react";
import CourseRegPrints from "../Components/courses/CourseRegPrint";
// import Sidebar from "../Components/Sidebar";
import AppLayout from "../Layouts/AppLayout";

const CourseRegistrationPrints = () => {
    return (
        <AppLayout>
            <>
                {/* <Sidebar /> */}
                <CourseRegPrints />
            </>
        </AppLayout>
    );
};

export default CourseRegistrationPrints;
