import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
}

export interface ViewCurricData {
  programme: string;
  session: string;
}

const initialState: Values = {
  loading: false,
  data: null,
};

export const ViewCurriculum = createAsyncThunk(
  "viewCurricData",
  async (data: ViewCurricData) => {
    return axiosInstance
      .post("/courses/curriculum", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const ViewCurriculumSlice = createSlice({
  name: "ViewCurriculum",
  initialState,
  reducers: {
    clearViewCurriculum: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewCurriculum.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(ViewCurriculum.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(ViewCurriculum.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
  },
});

export const { clearViewCurriculum } = ViewCurriculumSlice.actions;

export default ViewCurriculumSlice.reducer;
