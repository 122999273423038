import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { AppDispatch, RootState } from "../../state/store";
import { getAllUsers } from "../../state/user/allUsers";
import LinearDeterminate from "../reusable/LinearDeterminate";
import * as Yup from "yup";
import {
  clearCreateUser,
  CreateUser,
  CreateUserData,
} from "../../state/user/createUser";
import BtnLoader from "../reusable/btnLoader";
import DeleteModal from "../modals/DeleteModal";
import { Modal } from "react-bootstrap";
import {
  DeleteUserAccount,
  UpdateUserAccount,
  UpdateUserData,
} from "../../state/user/userActions";

const NewUsr = () => {
  const [delModal, setDelModal] = useState<any>();
  const [delModalShow, setDelModalShow] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<any>();
  const [editModalShow, setEditModalShow] = useState<boolean>(false);
  // const [creatorEmail, setCreatorEmail] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();
  const {
    allUsers: { allLoading, allUserData },
    user: { currUserData },
    createUser: { createData, createLoading },
    userActions: { deleteData, deleteLoading, updateData, updateLoading },
  } = useSelector((state: RootState) => state);

  const initialValues: CreateUserData = {
    email: "",
    firstName: "",
    lastName: "",
    department: "",
    password: "",
    userTypeId: 1,
    createdBy: currUserData?.data?.email,
  };

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    password: Yup.string().required("Password is required").min(6),
    department: Yup.string().required("Department is required"),
    userTypeId: Yup.number(),
  });
  const UpdateValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
  });

  useEffect(() => {
    if (!allUserData) {
      dispatch(getAllUsers());
    }
    if (createData) {
      if (createData?.name === "AxiosError") {
        toast.error(
          `${
            createData?.message
              ? createData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (createData?.success === true) {
        dispatch(getAllUsers());
        toast.success(
          `${
            createData?.message
              ? createData?.message
              : "User created successfully"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch(clearCreateUser());
      }
      if (createData?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (updateData) {
      if (updateData?.name === "AxiosError") {
        toast.error(
          `${
            updateData?.message
              ? updateData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (updateData?.success === true) {
        dispatch(getAllUsers());
        setEditModalShow(false);
        toast.success(
          `${
            updateData?.message
              ? updateData?.message
              : "User updated successfully"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (updateData?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (deleteData) {
      if (deleteData?.name === "AxiosError") {
        toast.error(
          `${
            deleteData?.message
              ? deleteData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (deleteData?.success === true) {
        dispatch(getAllUsers());
        toast.success(
          `${
            deleteData?.message
              ? deleteData?.message
              : "User successfully deleted"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (deleteData?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [createData, deleteData, updateData]);

  const handleSubmit = ({
    email,
    firstName,
    lastName,
    department,
    password,
    userTypeId,
    createdBy,
  }: any) => {
    console.log(currUserData?.data?.email);
    const data: CreateUserData = {
      email,
      firstName,
      lastName,
      department,
      password,
      userTypeId: +userTypeId,
      createdBy: currUserData?.data?.email,
    };
    // console.log(data);
    dispatch(CreateUser(data));
  };

  const handleDelete = (id: number) => {
    dispatch(DeleteUserAccount(id));
  };

  const handleEditUser = (data: UpdateUserData) => {
    dispatch(UpdateUserAccount(data));
  };

  return (
    <div>
      <div className="fixed-top">
        {(allLoading || deleteLoading) && <LinearDeterminate />}
      </div>
      <ToastContainer />
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Create New User</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            Create a new Administrator that would also be capable of managing
            this platform.
          </p>
          <hr className="mt-1" />

          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <Field type="hidden" name="createdBy" />
                    <div className="col-md-4 my-2">
                      <label className="my-2 ms-1 fw-bold" htmlFor="firstName">
                        First name
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First name"
                        className={
                          errors.firstName && touched.firstName
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <label className="my-2 ms-1 fw-bold" htmlFor="email">
                        Last name
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last name"
                        className={
                          errors.lastName && touched.lastName
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <label className="my-2 ms-1 fw-bold" htmlFor="email">
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter user's email"
                        autoComplete="off"
                        className={
                          errors.email && touched.email
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <label className="my-2 ms-1 fw-bold" htmlFor="password">
                        Password
                      </label>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        autoComplete="off"
                        className={
                          errors.password && touched.password
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <label className="my-2 ms-1 fw-bold" htmlFor="department">
                        Department
                      </label>
                      <Field
                        type="text"
                        name="department"
                        id="department"
                        placeholder="Department"
                        autoComplete="off"
                        className={
                          errors.department && touched.department
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="department"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <label className="my-2 ms-1 fw-bold" htmlFor="userTypeId">
                        User's role
                      </label>
                      <Field
                        as="select"
                        name="userTypeId"
                        id="userTypeId"
                        type="userTypeId"
                        // defaultValue={1}
                        className={
                          errors.userTypeId && touched.userTypeId
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="1">Admin</option>
                        <option value="2">Super-Admin</option>
                      </Field>
                      <ErrorMessage
                        name="userTypeId"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-12 my-2">
                      {createLoading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Create user
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* REQUEST TABLE STARTS */}
        {allUserData?.data && allUserData?.data?.length === 0 ? (
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title py-2">No users yet</h2>
              </div>
            </div>
          </div>
        ) : (
          allUserData?.data && (
            <div className="container my-4">
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h5 className="page-title py-2">All users</h5>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>User Type</th>
                        <th>Department</th>
                        {/* <th className="w-1"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {allUserData?.data &&
                        allUserData?.data?.map((item: any, index: number) => (
                          <tr className="" key={item?.id}>
                            <td>{index + 1}</td>
                            <td className="text-muted">
                              {item?.firstName} {item?.lastName}
                            </td>
                            <td className="text-muted">{item?.email}</td>
                            <td className="text-muted">{item?.userType}</td>
                            <td className="text-muted">{item?.department}</td>
                            <td className="text-muted">
                              <span className="dropdown text-end col">
                                <button
                                  className="btn btn-sm btn-secondary dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Action
                                </button>
                                <ul className="dropdown-menu p-2 border-0">
                                  <li className="m-2">
                                    <button
                                      className={
                                        "btn btn-sm btn-success w-100 mx-auto py-1 px-2"
                                      }
                                      onClick={() => {
                                        setEditModalShow(true);
                                        setEditModal(item);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </li>
                                  <li className="m-2">
                                    <button
                                      className={
                                        "btn btn-sm btn-danger w-100 mx-auto py-1 px-2"
                                      }
                                      onClick={() => {
                                        setDelModalShow(true);
                                        setDelModal(item);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </li>
                                </ul>
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        )}
        {/* REQUEST TABLE ENDS */}

        {/* DELETE MODAL STARTS */}
        <div className="modal-blur ">
          <DeleteModal
            modal={delModal}
            show={delModalShow}
            onHide={() => setDelModalShow(false)}
            header={"Delete user"}
          >
            <Modal.Body className="p-4 pt-0">
              <p className="py-3">
                You are about to revoke{" "}
                <b>
                  {editModal?.firstName} {editModal?.lastName}
                </b>{" "}
                from being a user on this platform. <br />{" "}
                <b>Are you sure you want to delete ??? </b>
              </p>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setDelModalShow(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    handleDelete(delModal?.id);
                    setDelModalShow(false);
                  }}
                >
                  Delete
                </button>
              </div>
            </Modal.Body>
          </DeleteModal>
        </div>
        {/* DELETE MODAL STARTS */}

        {/* EDIT MODAL STARTS */}
        <div className="modal-blur ">
          <DeleteModal
            modal={editModal}
            show={editModalShow}
            onHide={() => setEditModalShow(false)}
            size={"modal-md"}
            header={`Edit user`}
          >
            <Modal.Body className="p-4 pt-0 pb-2">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  id: editModal?.id,
                }}
                validationSchema={UpdateValidationSchema}
                onSubmit={handleEditUser}
              >
                {(formik) => {
                  const { errors, touched, isValid, dirty } = formik;
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-12 my-2">
                          <label
                            className="my-2 ms-1 fw-bold"
                            htmlFor="firstName"
                          >
                            First name
                          </label>
                          <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First name"
                            className={
                              errors.firstName && touched.firstName
                                ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                                : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                            }
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="col-12 my-2">
                          <label className="my-2 ms-1 fw-bold" htmlFor="email">
                            Last name
                          </label>
                          <Field
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Last name"
                            className={
                              errors.lastName && touched.lastName
                                ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                                : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                            }
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="col-12 my-2">
                          {updateLoading ? (
                            <BtnLoader />
                          ) : (
                            <button
                              type="submit"
                              className={
                                !(dirty && isValid)
                                  ? "disabled-btn btn btn-success w-100 mx-auto py-3 px-3"
                                  : "btn btn-success w-100 mx-auto py-3 px-3"
                              }
                              disabled={!(dirty && isValid)}
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div className="modal-footer"></div>
            </Modal.Body>
          </DeleteModal>
        </div>
        {/* EDIT MODAL STARTS */}
      </div>
    </div>
  );
};

export default NewUsr;
