import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
  addLoading: boolean;
  addData: any;
  delLoading: boolean;
  delData: any;
  toggleLoading: boolean;
  toggleData: any;
}

export interface ViewStaffData {
  programme: string;
  session: string;
}

export interface AddStaffData {
  session: string;
  semester: number;
  courseId: string;
  staffId: string;
  leadRole: boolean;
}

export interface DelandToggleStaffData {
  session: string;
  semester: number;
  courseId: string;
  staffId: string;
}

const initialState: Values = {
  loading: false,
  data: null,
  addLoading: false,
  addData: null,
  delLoading: false,
  delData: null,
  toggleLoading: false,
  toggleData: null,
};

export const ViewStaffMappings = createAsyncThunk(
  "ViewStaffMappings",
  async (data: ViewStaffData) => {
    return axiosInstance
      .post("/courses/lecturers", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const AddStaffMapping = createAsyncThunk(
  "AddStaffMapping",
  async (data: AddStaffData) => {
    return axiosInstance
      .post("/courses/lecturers/add", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const ToggleStaffMapping = createAsyncThunk(
  "ToggleStaffMapping",
  async (data: DelandToggleStaffData) => {
    return axiosInstance
      .post("/courses/lecturers/toggle-role", data)
      .then((res) => {
        // console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const DelStaffMapping = createAsyncThunk(
  "DelStaffMapping",
  async (data: any) => {
    return axiosInstance
      .delete("/courses/lecturers", { data })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const StaffCourseMapSlice = createSlice({
  name: "staffCourseMap",
  initialState,
  reducers: {
    clearStaffCourseMapData: (state) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewStaffMappings.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(ViewStaffMappings.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(ViewStaffMappings.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(AddStaffMapping.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.addLoading = true;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(AddStaffMapping.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = payload;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(AddStaffMapping.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = payload;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(DelStaffMapping.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = true;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(DelStaffMapping.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = payload;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(DelStaffMapping.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = payload;
      state.toggleLoading = false;
      state.toggleData = null;
    });
    builder.addCase(ToggleStaffMapping.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = true;
      state.toggleData = null;
    });
    builder.addCase(ToggleStaffMapping.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = payload;
    });
    builder.addCase(ToggleStaffMapping.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.addLoading = false;
      state.addData = null;
      state.delLoading = false;
      state.delData = null;
      state.toggleLoading = false;
      state.toggleData = payload;
    });
  },
});

export const { clearStaffCourseMapData } = StaffCourseMapSlice.actions;

export default StaffCourseMapSlice.reducer;
