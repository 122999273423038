import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { ViewAllPrograms } from "../../state/programs/programs";
import { AppDispatch, RootState } from "../../state/store";
import LinearDeterminate from "../reusable/LinearDeterminate";

const ViewAllProgs = () => {
//   const [programsData, setProgramsData] = useState<[]>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    viewAllProg: { data,loading },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(ViewAllPrograms());
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    //   if (data?.success === true) {
    //     setProgramsData(data?.data);
    //   }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message?.name[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, []);

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">
          {loading && <LinearDeterminate />}
        </div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">View All Programs </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <p className="mb-0">
              See the list of all programs that are currently available
            </p>
            <hr className="mt-1" />

            {/* REQUEST TABLE STARTS */}
            {data?.data && data?.data?.length === 0 ? (
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h2 className="page-title py-2">No Results Found</h2>
                  </div>
                </div>
              </div>
            ) : (
              data?.data && (
                <div className="container my-4">
                  <div className="container-xl">
                    <div className="row g-2 align-items-center">
                      <div className="col">
                        {/* <h5 className="page-title py-2">Search Results</h5> */}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-vcenter card-table table-striped">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Program</th>
                            <th>Program Code</th>
                            <th>College</th>
                            <th>Level</th>
                            {/* <th className="w-1"></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {data?.data &&
                            data?.data?.map((item: any, index: number) => (
                              <tr className="" key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.programmeName}</td>
                                <td className="text-muted">
                                  {item?.programme}
                                </td>
                                <td className="text-muted">{item?.college}</td>
                                <td className="text-muted">{item?.level}</td>
                                {/* <td className="text-muted">
                              <button
                                className={
                                  "btn link__two w-100 mx-auto py-1 px-3"
                                }
                                onClick={() => handleSpecificStudent(item.id)}
                              >
                                More
                              </button>
                            </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
            )}
            {/* REQUEST TABLE ENDS */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllProgs;
