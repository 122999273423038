import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
  deleteLoading: boolean;
  deleteData: any;
}

export interface ViewAllocationsData {
  programme: string;
  session: string;
  semester: string;
  level: string;
}

const initialState: Values = {
  loading: false,
  data: null,
  deleteLoading: false,
  deleteData: null,
};

export const ViewAllocations = createAsyncThunk(
  "ViewAllocations",
  async (data: ViewAllocationsData) => {
    return axiosInstance
      .post("/students/allocation", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const DeleteAllocation = createAsyncThunk(
  "DeleteAllocation",
  async (id: number) => {
    return axiosInstance
      .delete(`/students/allocation/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const ViewAllocationsSlice = createSlice({
  name: "ViewAllAllocations",
  initialState,
  reducers: {
    clearViewAllocations: (state) => {
      state.loading = false;
      state.data = null;
      state.deleteLoading = false;
      state.deleteData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewAllocations.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.deleteLoading = false;
      state.deleteData = null;
    });
    builder.addCase(ViewAllocations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.deleteLoading = false;
      state.deleteData = null;
    });
    builder.addCase(ViewAllocations.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.deleteLoading = false;
      state.deleteData = null;
    });
    builder.addCase(DeleteAllocation.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.deleteLoading = true;
      state.deleteData = null;
    });
    builder.addCase(DeleteAllocation.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.deleteLoading = false;
      state.deleteData = payload;
    });
    builder.addCase(DeleteAllocation.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.deleteLoading = false;
      state.deleteData = payload;
    });
  },
});

export const { clearViewAllocations } = ViewAllocationsSlice.actions;

export default ViewAllocationsSlice.reducer;
