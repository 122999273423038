import { ErrorMessage, Field, Form, Formik } from "formik";
import BtnLoader from "./reusable/btnLoader";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import {
  getSuspendedStudents,
  suspendStudent,
  unsuspendStudent,
} from "../state/students/suspendStud";
import React, { useEffect, useState } from "react";
// import DeleteModal from "./modals/DeleteModal";
import LinearDeterminate from "./reusable/LinearDeterminate";

const SuspendStud = () => {
  const [suspendData, setSuspendData] = useState<[]>();
  // const [deleteState, setDeleteState] = useState<any>(<DeleteModal />);
  interface Values {
    matric: string;
  }
  const initialValues: Values = {
    matric: "",
  };
  const SignInSchema = Yup.object().shape({
    matric: Yup.string().required("Matric number is required"),
  });
  const {
    suspendStud: { defaultData, defaultLoading, loading, data, unsuspData },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!defaultData && !data && !unsuspData) {
      dispatch(getSuspendedStudents());
    }
    if (defaultData) {
      if (defaultData?.name === "AxiosError") {
        toast.error(
          `${
            defaultData?.message
              ? defaultData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (defaultData?.success === true) {
        setSuspendData(defaultData?.data);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message?.matricNumber
              ? data?.message?.matricNumber[0]
              : data?.message
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        // console.log(data);
        toast.success(`Account suspended`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSuspendData(data?.data);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message?.matricNumber
              ? data?.message?.matricNumber[0]
              : data?.message
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
    if (unsuspData) {
      if (unsuspData?.name === "AxiosError") {
        toast.error(
          `${
            unsuspData?.message
              ? unsuspData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (unsuspData?.success === true) {
        // console.log(unsuspData);
        toast.success(`Account successfully unsuspended`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSuspendData(unsuspData?.data);
      }
      if (unsuspData?.success === false) {
        toast.error(
          `${
            unsuspData?.message?.matricNumber
              ? unsuspData?.message?.matricNumber[0]
              : unsuspData?.message
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data, defaultData, unsuspData]);

  const handleSubmit = ({ matric }: any) => {
    const data = {
      matricNumber: matric,
      suspend: true,
    };
    dispatch(suspendStudent(data));
  };

  const handleUnsuspend = (matric: string) => {
    const data = {
      matricNumber: matric,
      suspend: false,
    };
    // console.log(data);
    dispatch(unsuspendStudent(data));
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">{defaultLoading && <LinearDeterminate />}</div>
      {/* <div className="fixed-top">
        {defaultLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </div> */}
      {/* {deleteState} */}
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Suspend Student Account</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            Suspend a student's account and also see updated list of suspended
            accounts
          </p>
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-8 my-2">
                      <Field
                        type="text"
                        name="matric"
                        id="matric"
                        placeholder="Student's matric number"
                        autoComplete="off"
                        className={
                          errors.matric && touched.matric
                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="matric"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Suspend
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* REQUEST TABLE STARTS */}
        {suspendData && suspendData?.length === 0 ? (
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title py-2">No Results Found</h2>
              </div>
            </div>
          </div>
        ) : (
          suspendData && (
            <div className="container my-4">
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h5 className="page-title py-2">Search Results</h5>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Matric Number</th>
                        <th>First name</th>
                        <th>Middle name</th>
                        <th>Surname</th>
                        {/* <th className="w-1"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {suspendData &&
                        suspendData?.map((item: any, index: number) => (
                          <tr className="" key={item?.id}>
                            <td>{index + 1}</td>
                            <td>{item?.studentId}</td>
                            <td className="text-muted">{item?.firstName}</td>
                            <td className="text-muted">{item?.middleName}</td>
                            <td className="text-muted">{item?.lastName}</td>
                            <td className="text-muted">
                              <button
                                className={
                                  "btn btn-danger w-100 mx-auto py-1 px-3"
                                }
                                onClick={() => handleUnsuspend(item?.studentId)}
                              >
                                Unsuspend
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        )}
        {/* REQUEST TABLE ENDS */}
      </div>
    </div>
  );
};

export default SuspendStud;
