import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
}

const initialState: Values = {
  loading: false,
  data: null,
};

export const ViewAllocationPrograms = createAsyncThunk(
  "ViewAllocationPrograms",
  async () => {
    return axiosInstance
      .get("/programmes/allocation")
      .then((res) => {
        //   console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const AllocationProgramsDataSlice = createSlice({
  name: "allocationPrograms",
  initialState,
  reducers: {
    clearAllocationProgramsData: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewAllocationPrograms.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(ViewAllocationPrograms.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(ViewAllocationPrograms.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
  },
});

export const { clearAllocationProgramsData } =
  AllocationProgramsDataSlice.actions;

export default AllocationProgramsDataSlice.reducer;
