import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Students {
  loading: boolean;
  data: any;
  singleData: any;
  singleLoading: boolean;
  resetData: any;
  resetLoading: boolean;
  editData: any;
  editLoading: boolean;
}

export interface resetData {
  staffId : string;
  password : string;
}

export interface SearchIntf {
  name: string;
}

export interface EditNameData {
  lastName: string;
  firstName: string;
  middleName: string;
  id: string;
}

const initialState: Students = {
  loading: false,
  data: null,
  singleData: null,
  singleLoading: false,
  resetData: null,
  resetLoading: false,
  editData: null,
  editLoading: false,
};

export const searchStaffName = createAsyncThunk(
  "searchStaffName",
  async (data: SearchIntf) => {
    return axiosInstance
      .post("/staffs/search-by-name", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const getStaffById = createAsyncThunk(
  "getStaffById",
  async (id: number) => {
    return axiosInstance
      .get(`/staffs/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const resetStaffPassword = createAsyncThunk(
  "resetStaffPassword",
  async (data: resetData) => {
    return axiosInstance
      .post("/staffs/reset-password", data )
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const editStaffName = createAsyncThunk(
  "editStaffName",
  async (data: EditNameData) => {
    return axiosInstance
      .put(`/students/${data.id}`, data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    clearStaffData: (state) => {
      state.loading = false;
      state.data = null;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchStaffName.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(searchStaffName.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(searchStaffName.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.singleData = null;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });

    builder.addCase(getStaffById.pending, (state) => {
      state.loading = false;
      // state.data = null;
      state.singleData = null;
      state.singleLoading = true;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(getStaffById.fulfilled, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      state.singleData = payload;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(getStaffById.rejected, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      state.singleData = payload;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = false;
      state.editData = null;
      state.editLoading = false;
    });
    builder.addCase(resetStaffPassword.pending, (state) => {
      state.loading = false;
      //   state.data = null;
      // state.singleData = payload;
      state.singleLoading = false;
      state.resetData = null;
      state.resetLoading = true;
    });
    builder.addCase(resetStaffPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      // state.singleData = payload;
      state.singleLoading = false;
      state.resetData = payload;
      state.resetLoading = false;
    });
    builder.addCase(resetStaffPassword.rejected, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      // state.singleData = payload;
      state.singleLoading = false;
      state.resetData = payload;
      state.resetLoading = false;
    });
    builder.addCase(editStaffName.pending, (state) => {
      state.loading = false;
      state.singleLoading = false;
      state.editData = null;
      state.editLoading = true;
    });
    builder.addCase(editStaffName.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.singleLoading = false;
      state.editData = payload;
      state.editLoading = false;
    });
    builder.addCase(editStaffName.rejected, (state, { payload }) => {
      state.loading = false;
      state.singleLoading = false;
      state.editData = payload;
      state.editLoading = false;
    });
  },
});

export const { clearStaffData } = staffSlice.actions;

export default staffSlice.reducer;
