import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  deleteLoading: boolean;
  deleteData: any;
  updateLoading: boolean;
  updateData: any;
}

export interface UpdateUserData {
  firstName: string;
  lastName: string;
  id?: number;
}

const initialState: Values = {
  deleteLoading: false,
  deleteData: null,
  updateLoading: false,
  updateData: null,
};

export const DeleteUserAccount = createAsyncThunk(
  "DeleteUserAccount",
  async (id: number) => {
    return axiosInstance
      .delete(`/users/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const UpdateUserAccount = createAsyncThunk(
  "UpdateUserAccount",
  async (data: UpdateUserData) => {
    return axiosInstance
      .patch(`/users/${data?.id}`, data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const UserActionSlice = createSlice({
  name: "UserActionSlice",
  initialState,
  reducers: {
    clearUserUpdates: (state) => {
      state.deleteLoading = false;
      state.deleteData = null;
      state.updateLoading = false;
      state.updateData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(DeleteUserAccount.pending, (state) => {
      state.deleteLoading = true;
      //   state.data = null;
      state.updateLoading = false;
      state.updateData = null;
    });
    builder.addCase(DeleteUserAccount.fulfilled, (state, { payload }) => {
      state.deleteLoading = false;
      state.deleteData = payload;
      state.updateLoading = false;
      state.updateData = null;
    });
    builder.addCase(DeleteUserAccount.rejected, (state, { payload }) => {
      state.deleteLoading = false;
      state.deleteData = payload;
      state.updateLoading = false;
      state.updateData = null;
    });
    builder.addCase(UpdateUserAccount.pending, (state) => {
      state.deleteLoading = false;
      state.deleteData = null;
      state.updateLoading = true;
      state.updateData = null;
    });
    builder.addCase(UpdateUserAccount.fulfilled, (state, { payload }) => {
      state.deleteLoading = false;
      state.deleteData = null;
      state.updateLoading = false;
      state.updateData = payload;
    });
    builder.addCase(UpdateUserAccount.rejected, (state, { payload }) => {
      state.deleteLoading = false;
      state.deleteData = null;
      state.updateLoading = false;
      state.updateData = payload;
    });
  },
});

export const { clearUserUpdates } = UserActionSlice.actions;

export default UserActionSlice.reducer;
