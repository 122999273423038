import React from "react";
import CourseReg from "../Components/CourseReg";
// import Sidebar from "../Components/Sidebar";
import AppLayout from "../Layouts/AppLayout";

const CourseRegistration = () => {
    return (
        <AppLayout>
            <>
                {/* <Sidebar /> */}
                <CourseReg />
            </>
        </AppLayout>
    );
};

export default CourseRegistration;
