import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import BtnLoader from "../reusable/btnLoader";
import Sessions from "../reusable/sessions";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import {
  AddReplacementCourse,
  HarmoniseCourseData,
  ViewHarmonisedCourses,
} from "../../state/courses/harmoniseCourse";
import { toast, ToastContainer } from "react-toastify";
import LinearDeterminate from "../reusable/LinearDeterminate";

const HarmoniseCourseDetails = () => {
  const [ALLHarmonisedState, setAllHarmonisedState] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    harmoniseCourse: { data, harmonisedLoading, loading, harmonisedData },
  } = useSelector((state: RootState) => state);
  const initialValues = {
    session: "",
    oldCourseCode: "",
    newCourseCodes: "",
  };
  const ValidationSchema = Yup.object().shape({
    session: Yup.string().required("Session is required"),
    oldCourseCode: Yup.string().required("Old course code is required"),
    newCourseCodes: Yup.string().required("New course code is required"),
  });

  useEffect(() => {
    if (!data) {
      dispatch(ViewHarmonisedCourses());
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setAllHarmonisedState(data?.data);
      }
      if (data?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (harmonisedData) {
      if (harmonisedData?.name === "AxiosError") {
        toast.error(
          `${
            harmonisedData?.message
              ? harmonisedData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (harmonisedData?.success === true) {
        setAllHarmonisedState(harmonisedData?.data);
        toast.success(`Course harmonisation successful`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (data?.success === false) {
        toast.error(`${"Something went wrong please try again"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [data, harmonisedData]);

  const handleSubmit = ({ session, oldCourseCode, newCourseCodes }: any) => {
    const data: HarmoniseCourseData = {
      session,
      oldCourseCode,
      newCourseCodes: newCourseCodes.trim().split(","),
    };
    console.log(data);

    dispatch(AddReplacementCourse(data));
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">{loading && <LinearDeterminate />}</div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Harmonise Course</h2>
                  <p className="mb-0">Add replacement courses for old course</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0"></p>
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-6 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <Field
                        name="oldCourseCode"
                        type="text"
                        placeholder="Enter old course code (i.e to be changed)"
                        //   defaultValue={initialsession}
                        className={
                          errors.oldCourseCode && touched.oldCourseCode
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      ></Field>
                      <ErrorMessage
                        name="oldCourseCode"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-12 my-2">
                      <Field
                        as="textarea"
                        name="newCourseCodes"
                        type="newCourseCodes"
                        placeholder="Enter new course code(s) or replacements"
                        //   defaultValue={initialnewCourseCodes}
                        className={
                          errors.newCourseCodes && touched.newCourseCodes
                            ? "input-error form-control py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      ></Field>
                      <ErrorMessage
                        name="newCourseCodes"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-12 my-2">
                      {harmonisedLoading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Get all
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div>
            {ALLHarmonisedState && ALLHarmonisedState?.length === 0 ? (
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h2 className="page-title py-2">No Results Found</h2>
                  </div>
                </div>
              </div>
            ) : (
              ALLHarmonisedState && (
                <div className="container my-4">
                  <div className="container-xl">
                    <div className="row g-2 align-items-center">
                      <div className="col">
                        <h5 className="page-title py-2 fw-bold">
                          Harmonised courses
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-vcenter card-table table-striped">
                        <thead>
                          <tr>
                            <th>S/N</th>
                            <th>Course</th>
                            <th>Replacement</th>
                            <th>Session</th>
                            {/* <th className="w-1"></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {ALLHarmonisedState &&
                            ALLHarmonisedState.slice()
                              .sort((a: any, b: any) =>
                                a.oldCourseCode.localeCompare(b.oldCourseCode)
                              )
                              ?.map((item: any, index: number) => (
                                <tr className="" key={item?.id}>
                                  <td>{index + 1}</td>
                                  <td className="text-muted">
                                    {item?.oldCourseCode}
                                  </td>
                                  <td className="text-muted">
                                    {item?.newCourseCodes?.map(
                                      (i: any, ind: number) =>
                                        ind + 1 !== item?.newCourseCodes?.length
                                          ? `${i}, `
                                          : i
                                    )}
                                  </td>
                                  <td className="text-muted">
                                    {item?.session
                                      ? item?.session
                                      : "Not specified"}
                                  </td>
                                  {/* <td className="text-muted">
                                  <button
                                    className="btn btn-danger py-1"
                                    onClick={() => {
                                      setModalShow(true);
                                      setModal(item);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td> */}
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HarmoniseCourseDetails;
