import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewAllStaff } from "../../state/staff/allStaff";
import { AppDispatch, RootState } from "../../state/store";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import LinearDeterminate from "../reusable/LinearDeterminate";
import { Link } from "react-router-dom";

const AllAcadStaffDetails = () => {
  const [paginate, setPaginate] = useState<any>();
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const [allStaff, setAllStaff] = useState<[]>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    allStaff: { loading, data },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (!data) {
      dispatch(ViewAllStaff(page));
    }

    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setAllStaff(data?.data?.itemList);
        setPaginate(data?.data?.pagination);
        setCount(data?.data?.paginaton?.lastPage);
        setPage(data?.data?.paginaton?.currentPage);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message?.name[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data]);

  const handleChange = (e: any, page: number) => {
    // console.log(page);
    setPage(page);
    dispatch(ViewAllStaff(page));
  };
  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">{loading && <LinearDeterminate />}</div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">All Academic Staff</h2>
                  <p className="mb-0">All academic staff registered on SSHUB</p>
                </div>
                <div className="col text-center text-md-end">
                  <Link
                    to="/staff/add-new-staff"
                    className="btn dashnav__menu--btn py-3 px-4"
                  >
                    Add new staff
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5">
          <hr className="mt-1" />
          {allStaff && (
            <div className="card mb-4">
              <div className="table-responsive">
                <table className="table table-vcenter card-table table-striped">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Image</th>
                      <th>Staff ID</th>
                      <th>Name</th>
                      <th>Sex</th>
                      <th>Email</th>
                      <th>Level</th>
                      <th>Department</th>
                      <th>Faculty</th>
                      {/* <th className="w-1"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {allStaff &&
                      allStaff.map((item: any, index: number) => (
                        <tr className="" key={item?.id}>
                          <td>
                            {paginate?.currentPage * 10 + (index + 1) - 10}
                          </td>
                          <td>
                            <a
                              href={item?.picture}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "10%",
                                }}
                                src={item?.picture}
                                alt=""
                                className="img-fluid w-75 mx-auto"
                              />
                            </a>
                          </td>
                          <td>{item?.staffId}</td>
                          <td className="text-muted">{`${item?.firstName} ${item?.middleName} ${item?.lastName}`}</td>
                          <td className="text-muted">{item?.sex}</td>
                          <td className="text-muted">{item?.email}</td>
                          <td className="text-muted">{item?.level}</td>
                          <td className="text-muted">{item?.departmentCode}</td>
                          <td className="text-muted">{item?.faculty}</td>
                          {/* <td className="text-muted">
                              <button
                                className={
                                  "btn link__two w-100 mx-auto py-1 px-3"
                                }
                                onClick={() => handleSpecificStaff(item.id)}
                              >
                                More
                              </button>
                            </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
                {/* PAGINATION STARTS */}
                <div className="py-3">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Pagination
                      count={paginate && paginate?.lastPage}
                      onChange={handleChange}
                      showFirstButton
                      showLastButton
                    />
                  </Box>
                </div>
                {/* PAGINATION ENDS */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllAcadStaffDetails;
