import { ErrorMessage, Field, Formik, Form } from "formik";
import React, { useEffect } from "react";
import BtnLoader from "../reusable/btnLoader";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { addStaff, AddStaffData } from "../../state/staff/addStaff";

const AddStaffDetails = () => {
  const initialValues: AddStaffData = {
    staffId: "",
    password: "",
  };

  const SignInSchema = Yup.object().shape({
    staffId: Yup.string().required("Staff ID is required"),
    password: Yup.string().required("Password is required"),
  });

  const dispatch = useDispatch<AppDispatch>();
  const {
    addStaff: { loading, data },
  } = useSelector((state: RootState) => state);

  const handleSubmit = ({ staffId, password }: AddStaffData) => {
    dispatch(addStaff({ staffId, password }));
  };
  // console.log({loading, data});

  useEffect(() => {
    if (data) {
      // console.log(data);
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        // setStudentData(data?.data);
        toast.success(`${"New academic staff added"}`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data]);

  return (
    <div>
      <ToastContainer />
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Add New Staff</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            Add a new <strong>academic</strong> staff to SSHub
          </p>
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-4 my-2">
                      <Field
                        type="text"
                        name="staffId"
                        id="staffId"
                        placeholder="BUI/ACAD/####"
                        autoComplete="off"
                        className={
                          errors.staffId && touched.staffId
                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="staffId"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      <Field
                        type="text"
                        name="password"
                        id="password"
                        placeholder="Password(Optional)"
                        autoComplete="off"
                        className={
                          errors.password && touched.password
                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Search
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* <div className="container">{result}</div> */}
      </div>
    </div>
  );
};

export default AddStaffDetails;
