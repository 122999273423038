import React, { Suspense } from "react";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Components/reusable/ScrollToTop";
import routes from "./routes";
import ProtectedRoutes from "./routes/protectedRoutes";
// import Dashboard from "./Pages/Dashboard";
import "@tabler/core/dist/css/tabler.min.css";
import "@tabler/core/dist/js/tabler.min.js";

// PAGES
const Login = React.lazy(() => import("./Pages/Auth/Login"));
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import("./Pages/error/Page404"));

// LOADING STATE
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <ScrollToTop>
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            {/* <Route path="/home" element={<Dashboard />} /> */}
            <Route path="/page404" element={<Page404 />} />
            {/* <Route path="/students/check-update-info" element={<CheckUpdate />} /> */}
            <Route element={<ProtectedRoutes />}>
              {routes.map((item) => (
                <Route
                  key={item.id}
                  path={`${item.path}`}
                  element={item.element}
                />
              ))}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
