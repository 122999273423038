import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import BtnLoader from "../reusable/btnLoader";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {
  editStaffName,
  resetStaffPassword,
  SearchIntf,
  searchStaffName,
  getStaffById,
} from "../../state/staff/staff";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import StudentModal from "../modals/StudModal";
import { Modal } from "react-bootstrap";
import LinearDeterminate from "../reusable/LinearDeterminate";

const Search = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [staffData, setStaffData] = useState<[]>();
  const [modal, setModal] = useState<any>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [editForm, setEditForm] = useState<any>("");

  const initialValues = {
    name: "",
  };
  const SignInSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const SubmitSchema = Yup.object().shape({
    firstName: Yup.string().required("Firstname cannot be empty"),
    lastName: Yup.string().required("Lastname cannot be empty"),
  });
  const {
    staff: {
      loading,
      data,
      singleData,
      singleLoading,
      resetData,
      resetLoading,
      editData,
      editLoading,
    },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (editData) {
      if (editData?.name === "AxiosError") {
        toast.error(
          `${
            editData?.message
              ? editData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (editData?.success === true) {
        toast.success(`Staff name change successful`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setModal(editData?.data);
      }
      if (editData?.success === false) {
        toast.error(
          `${
            editData?.message
              ? editData?.message?.matricNumber[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
    if (resetData) {
      if (resetData?.name === "AxiosError") {
        toast.error(
          `${
            resetData?.message
              ? resetData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (resetData?.success === true) {
        // console.log(resetData);
        toast.success(`Password change successful`, {
          position: "top-center",
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (resetData?.success === false) {
        toast.error(
          `${
            resetData?.message
              ? resetData?.message?.matricNumber[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
    if (singleData) {
      if (singleData?.name === "AxiosError") {
        toast.error(
          `${
            singleData?.message
              ? singleData?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (singleData?.success === true) {
        // console.log(singleData);
        setModal(singleData.data);
        setModalShow(true);
        setEditForm("");
      }
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setStaffData(data?.data);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message?.name[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data, singleData, resetData, editData]);

  const handleSubmit = ({ name }: SearchIntf) => {
    dispatch(searchStaffName({ name }));
  };
  const handleSpecificStaff = (id: number) => {
    dispatch(getStaffById(id));
  };
  const handleReset = (staffId: string, password: string) => {
    dispatch(resetStaffPassword({ staffId, password }));
  };

  const submitEdit = ({ firstName, middleName, lastName, id }: any) => {
    // console.log(firstName, middleName, lastName, id);
    dispatch(editStaffName({ firstName, middleName, lastName, id }));
  };

  const handleEdit = (
    firstName: any,
    middleName: any,
    lastName: any,
    id: any
  ) => {
    setEditForm(
      <Formik
        initialValues={{ firstName, middleName, lastName, id }}
        validationSchema={SubmitSchema}
        onSubmit={submitEdit}
      >
        {(formik) => {
          const { errors, touched, isValid, dirty } = formik;
          return (
            <Form>
              <div className="row">
                <Field
                  type="hidden"
                  name="id"
                  id="id"
                  placeholder=""
                  autoComplete="off"
                  className={
                    errors.id && touched.id
                      ? "input-error form-control name py-2 px-2 shadow-none w-100 mx-auto"
                      : "form-control name py-2 px-2 shadow-none w-100 mx-auto"
                  }
                />
                <div className="col-md-6 col-lg-3 my-1">
                  <Field
                    type="text"
                    name="lastName"
                    id="name"
                    placeholder="Last Name"
                    autoComplete="off"
                    className={
                      errors.lastName && touched.lastName
                        ? "input-error form-control name py-2 px-2 shadow-none w-100 mx-auto"
                        : "form-control name py-2 px-2 shadow-none w-100 mx-auto"
                    }
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6 col-lg-3 my-1">
                  <Field
                    type="text"
                    name="firstName"
                    id="name"
                    placeholder="First Name"
                    autoComplete="off"
                    className={
                      errors.firstName && touched.firstName
                        ? "input-error form-control name py-2 px-2 shadow-none w-100 mx-auto"
                        : "form-control name py-2 px-2 shadow-none w-100 mx-auto"
                    }
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6 col-lg-3 my-1">
                  <Field
                    type="text"
                    name="middleName"
                    id="name"
                    placeholder="Middle Name"
                    autoComplete="off"
                    className={
                      errors.middleName && touched.middleName
                        ? "input-error form-control name py-2 px-2 shadow-none w-100 mx-auto"
                        : "form-control name py-2 px-2 shadow-none w-100 mx-auto"
                    }
                  />
                  <ErrorMessage
                    name="middleName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6 col-lg-3 my-1">
                  {editLoading ? (
                    <span className="btn btn-danger mx-3 py-1 px-4">
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    </span>
                  ) : (
                    <button
                      type="submit"
                      className={
                        !(dirty && isValid)
                          ? "disabled-btn btn btn-primary w-100 mx-auto py-2 px-3"
                          : "btn btn-primary w-100 mx-auto py-2 px-3"
                      }
                      disabled={!(dirty && isValid)}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div>
      <div className="page-wrapper">
        <ToastContainer />
        <div className="fixed-top">
          {singleLoading && <LinearDeterminate />}
        </div>
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <h2 className="page-title">Search Staff</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">Search staff by name</p>
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-8 my-2">
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter Name"
                        autoComplete="off"
                        className={
                          errors.name && touched.name
                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Search
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* REQUEST TABLE STARTS */}
        {staffData && staffData?.length === 0 ? (
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">
                <h2 className="page-title py-2">No Results Found</h2>
              </div>
            </div>
          </div>
        ) : (
          staffData && (
            <div className="container my-4">
              <div className="container-xl">
                <div className="row g-2 align-items-center">
                  <div className="col">
                    <h5 className="page-title py-2">Search Results</h5>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-vcenter card-table table-striped">
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Staff ID</th>
                        <th>First name</th>
                        <th>Middle name</th>
                        <th>Surname</th>
                        {/* <th className="w-1"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {staffData &&
                        staffData.map((item: any, index: number) => (
                          <tr className="" key={item?.id}>
                            <td>{index + 1}</td>
                            <td>{item?.staffId}</td>
                            <td className="text-muted">{item?.firstName}</td>
                            <td className="text-muted">{item?.middleName}</td>
                            <td className="text-muted">{item?.lastName}</td>
                            <td className="text-muted">
                              <button
                                className={
                                  "btn link__two w-100 mx-auto py-1 px-3"
                                }
                                onClick={() => handleSpecificStaff(item.id)}
                              >
                                More
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        )}
        {/* REQUEST TABLE ENDS */}

        {/* SPECIFIC STUDENT MODAL STARTS */}
        <div className="modal-blur ">
          <StudentModal
            modal={modal}
            show={modalShow}
            onHide={() => setModalShow(false)}
          >
            <Modal.Body className="pt-0">
              {singleData && (
                <div className="p-lg-4 pt-0 pb-0 pt-lg-0 pb-lg-0">
                  <div className="row align-items-center ">
                    <div className="col-md-2">
                      {modal?.picture && modal?.picture.length > 0 ? (
                        <img
                          style={{
                            width: "100px",
                            height: "110px",
                            borderRadius: "50%",
                          }}
                          src={modal?.picture}
                          alt=""
                          className="img-fluid w-75 mx-auto"
                        />
                      ) : modal?.sex === "Female" ||
                        modal?.sex === "female" ||
                        modal?.sex === "FEMALE" ? (
                        <img
                          src="/assets/avatar-female.png"
                          alt=""
                          className="img-fluid w-75 mx-auto"
                        />
                      ) : (
                        <img
                          src="/assets/avatar-male.png"
                          alt=""
                          className="img-fluid w-75 mx-auto"
                        />
                      )}
                      {}
                    </div>
                    <div className="col-md-10">
                      <h5 className="fw-normal m-0">
                        {editData?.success === true ? (
                          <>
                            <strong>{`${editData?.data?.lastName}`}</strong>{" "}
                            {`${editData?.data?.firstName} ${editData?.data?.middleName}`}
                          </>
                        ) : (
                          <>
                            <strong> {`${modal?.lastName}`}</strong>{" "}
                            {`${modal?.firstName} ${modal?.middleName}`}
                          </>
                        )}
                        <span
                          className="btn btn-danger mx-3 py-1 px-3"
                          onClick={() =>
                            handleEdit(
                              modal?.firstName,
                              modal?.middleName,
                              modal?.lastName,
                              modal?.id
                            )
                          }
                        >
                          Edit
                        </span>
                      </h5>
                      <p className="m-0">
                        {modal?.staffId ? modal?.staffId : "No Staff ID"}
                      </p>
                      {editForm}
                    </div>
                    <div className="row mt-3">
                      <hr />
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Email</p>
                        <p className="content--subtext">{modal?.email}</p>
                      </div>{" "}
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Phone Number</p>
                        <p className="content--subtext">{modal?.phoneNumber}</p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">D.O.B</p>
                        <p className="content--subtext">{modal?.dateOfBirth}</p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Sex</p>
                        <p className="content--subtext">{modal?.sex}</p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Level</p>
                        <p className="content--subtext">{modal?.level}</p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">
                          Departmental Code
                        </p>
                        <p className="content--subtext">
                          {modal?.departmentCode}
                        </p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Faculty</p>
                        <p className="content--subtext">{modal?.faculty} </p>
                      </div>
                      {/* <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Hostel</p>
                        <p className="content--subtext">{modal?.hostelName} </p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Room Number</p>
                        <p className="content--subtext">{modal?.roomNumber} </p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Year of Entry</p>
                        <p className="content--subtext">
                          {modal?.yearOfEntry}{" "}
                        </p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Mode of Entry</p>
                        <p className="content--subtext">
                          {modal?.modeOfEntry}{" "}
                        </p>
                      </div> */}
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">
                          State of Origin
                        </p>
                        <p className="content--subtext">
                          {modal?.stateOfOrigin}{" "}
                        </p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">
                          Local Government
                        </p>
                        <p className="content--subtext">
                          {modal?.localGovernment}{" "}
                        </p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Denomination</p>
                        <p className="content--subtext">
                          {modal?.denomination}{" "}
                        </p>
                      </div>
                      <div className="col-md-6 col-xl-4 my-2">
                        <p className="content--headtext mb-1">Salary Grade</p>
                        <p className="content--subtext">
                          {modal?.salaryGrade}{" "}
                        </p>
                      </div>
                      <div className="col-12 my-2">
                        <p className="content--headtext mb-1">Password</p>
                        <p className="content--subtext">
                          {resetData?.success === true
                            ? resetData?.data?.password
                            : modal?.password}{" "}
                          {resetLoading ? (
                            <span className="btn btn-danger mx-3 py-1 px-4">
                              <span
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </span>
                            </span>
                          ) : (
                            <span
                              className="btn btn-danger mx-3 py-1 px-3"
                              onClick={() =>
                                handleReset(modal?.staffId, modal?.middleName)
                              }
                            >
                              Reset
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
          </StudentModal>
        </div>
        {/* SPECIFIC STUDENT MODAL ENDS */}
      </div>
    </div>
  );
};

export default Search;
