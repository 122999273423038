import React from "react";
import RegCourseMultiple from "../Components/RegCourseMultiple";
import AppLayout from "../Layouts/AppLayout";

const RegisterCoursesMultiple = () => {
    return (
        <AppLayout>
            <>
                <RegCourseMultiple />
            </>
        </AppLayout>
    );
};

export default RegisterCoursesMultiple;
