import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import BtnLoader from "../reusable/btnLoader";
import * as Yup from "yup";
import Sessions from "../reusable/sessions";
import { ViewDistinctCourses } from "../../state/courses/distinctCourses";
import { toast, ToastContainer } from "react-toastify";
import LinearDeterminate from "../reusable/LinearDeterminate";
import { ViewScore, ViewScoreData } from "../../state/courses/viewScore";

const ViewScoreDetails = () => {
  const [scoreData, setScoreData] = useState<[]>();
  const [distinctData, setDistinctData] = useState<any[]>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    distinctCourses,
    viewScore: { loading, data },
  } = useSelector((state: RootState) => state);
  const initialValues: ViewScoreData = {
    courseCode: "",
    session: "",
  };
  const SignInSchema = Yup.object().shape({
    courseCode: Yup.string().required("Course code is required"),
    session: Yup.string().required("Session is required"),
  });

  useEffect(() => {
    if (!distinctCourses?.data) {
      dispatch(ViewDistinctCourses());
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setScoreData(data?.data);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message?.session[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data]);

  const handleSubmit = (values: ViewScoreData) => {
    // console.log(values);
    dispatch(ViewScore(values));
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">
        {distinctCourses?.data?.data?.loading && <LinearDeterminate />}
      </div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">View Score</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            View scores of students based on course and session
          </p>
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="courseCode"
                        type="courseCode"
                        //   defaultValue={initialcourseCode}
                        className={
                          errors.courseCode && touched.courseCode
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select course code ...
                        </option>
                        {distinctCourses?.data?.data
                          .slice()
                          .sort((a: any, b: any) =>
                            a.courseCode.localeCompare(b.courseCode)
                          )
                          .map((item: any, index: number) => (
                            <option value={item.courseCode} key={index}>
                              {item.courseCode}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage
                        name="courseCode"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Get Scores
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* REQUEST RESPONSE TABLE STARTS */}
        <div>
          {scoreData && scoreData?.length === 0 ? (
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title py-2">No Results Found</h2>
                </div>
              </div>
            </div>
          ) : (
            scoreData && (
              <div className="container my-4">
                <div className="container-xl">
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <h5 className="page-title py-2">Search Results</h5>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Matric Number</th>
                          <th>Course Code</th>
                          <th>Course Title</th>
                          <th>Course Type</th>
                          <th>Unit</th>
                          <th>Programme</th>
                          {/* <th>Session</th> */}
                          <th>Semester</th>
                          <th>CA</th>
                          <th>Exam</th>
                          <th>Total</th>

                          {/* <th className="w-1"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {scoreData &&
                          scoreData?.map((item: any, index: number) => (
                            <tr className="" key={item?.id}>
                              <td>{index + 1}</td>
                              <td>{item?.matricNumber}</td>
                              <td>{item?.courseCode}</td>
                              <td className="text-muted">
                                {item?.courseTitle}
                              </td>
                              <td className="text-muted">{item?.courseType}</td>
                              <td className="text-muted">{item?.unit}</td>
                              <td className="text-muted">{item?.programme}</td>
                              {/* <td className="text-muted">{item?.session}</td> */}
                              <td className="text-muted">
                                {item?.semesterName}
                              </td>
                              <td className="fw-bold">{item?.ca}</td>
                              <td className="fw-bold">{item?.exam}</td>
                              <td className="fw-bold">
                                {+item?.exam + +item?.ca}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {/* REQUEST RESPONSE TABLE ENDS */}
      </div>
    </div>
  );
};

export default ViewScoreDetails;
