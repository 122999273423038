import React from "react";
import AddStaffDetails from "../Components/staff/AddStaffDetails";
import AppLayout from "../Layouts/AppLayout";

const AddNewStaff = () => {
    return (
        <AppLayout>
            <>
                <AddStaffDetails />
            </>
        </AppLayout>
    );
};

export default AddNewStaff;
