import Navbar from "../Components/NavBar";
import Sidebar from "../Components/Sidebar";

// import Footer from "../Components/Footer";

type PageProps = {
  children: JSX.Element;
};

const AppLayout = ({ children }: PageProps) => {
  return (
    <>
      <Sidebar />
      <Navbar />
      {children}
      {/* <Footer /> */}
    </>
  );
};

export default AppLayout;
