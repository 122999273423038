import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
  removeLoading: boolean;
  removeData: any;
  approveLoading: boolean;
  approveData: any;
}

export interface ViewRegData {
  matricNumber: string;
  session: string;
  semester: string;
}

const initialState: Values = {
  loading: false,
  data: null,
  removeLoading: false,
  removeData: null,
  approveLoading: false,
  approveData: null,
};

export const ViewStudRegData = createAsyncThunk(
  "viewRegData",
  async (data: any) => {
    return axiosInstance
      .post("/students/courses/registered-courses", data)
      .then((res) => {
        //   console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const ApproveCourse = createAsyncThunk(
  "ApproveCourse",
  async (id: number) => {
    return axiosInstance
      .put(`/students/courses/approve/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const RemoveCourse = createAsyncThunk(
  "RemoveCourse",
  async (id: number) => {
    return axiosInstance
      .delete(`/students/courses/remove/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const ViewRegDataSlice = createSlice({
  name: "ViewStudCourseReg",
  initialState,
  reducers: {
    clearViewStudRegData: (state) => {
      state.loading = false;
      state.data = null;
      state.removeLoading = false;
      state.removeData = null;
      state.approveLoading = false;
      state.approveData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewStudRegData.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.removeLoading = false;
      state.removeData = null;
      state.approveLoading = false;
      state.approveData = null;
    });
    builder.addCase(ViewStudRegData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.removeLoading = false;
      state.removeData = null;
      state.approveLoading = false;
      state.approveData = null;
    });
    builder.addCase(ViewStudRegData.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.removeLoading = false;
      state.removeData = null;
      state.approveLoading = false;
      state.approveData = null;
    });
    builder.addCase(ApproveCourse.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.removeLoading = false;
      state.removeData = null;
      state.approveLoading = true;
      state.approveData = null;
    });
    builder.addCase(ApproveCourse.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.removeLoading = false;
      state.removeData = null;
      state.approveLoading = false;
      state.approveData = payload;
    });
    builder.addCase(ApproveCourse.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.removeLoading = false;
      state.removeData = null;
      state.approveLoading = false;
      state.approveData = payload;
    });
    builder.addCase(RemoveCourse.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.removeLoading = true;
      state.removeData = null;
      state.approveLoading = false;
      state.approveData = null;
    });
    builder.addCase(RemoveCourse.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.removeLoading = false;
      state.removeData = payload;
      state.approveLoading = false;
      state.approveData = null;
    });
    builder.addCase(RemoveCourse.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.removeLoading = false;
      state.removeData = payload;
      state.approveLoading = false;
      state.approveData = null;
    });
  },
});

export const { clearViewStudRegData } = ViewRegDataSlice.actions;

export default ViewRegDataSlice.reducer;
