import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
  deleteLoading: boolean;
  deleteData: any;
}

export interface AllocateStudentData {
  matricNumbers: any;
  programme: string;
  session: string;
  semester: string;
  level: string;
  spill: string;
}

const initialState: Values = {
  loading: false,
  data: null,
  deleteLoading: false,
  deleteData: null,
};

export const AllocateStudents = createAsyncThunk(
  "AllocateStudents",
  async (data: AllocateStudentData) => {
    return axiosInstance
      .post("/students/allocation/add", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const DeleteCorrespondAllocation = createAsyncThunk(
  "DeleteCorrespondAllocation",
  async (id: number) => {
    return axiosInstance
      .delete(`/students/allocation/${id}`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const AllocateStudentsSlice = createSlice({
  name: "AllocateStud",
  initialState,
  reducers: {
    clearAllocateStudents: (state) => {
      state.loading = false;
      state.data = null;
      state.deleteLoading = false;
      state.deleteData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AllocateStudents.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.deleteLoading = false;
      state.deleteData = null;
    });
    builder.addCase(AllocateStudents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.deleteLoading = false;
      state.deleteData = null;
    });
    builder.addCase(AllocateStudents.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.deleteLoading = false;
      state.deleteData = null;
    });
    builder.addCase(DeleteCorrespondAllocation.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.deleteLoading = true;
      state.deleteData = null;
    });
    builder.addCase(
      DeleteCorrespondAllocation.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.data = null;
        state.deleteLoading = false;
        state.deleteData = payload;
      }
    );
    builder.addCase(
      DeleteCorrespondAllocation.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.data = null;
        state.deleteLoading = false;
        state.deleteData = payload;
      }
    );
  },
});

export const { clearAllocateStudents } = AllocateStudentsSlice.actions;

export default AllocateStudentsSlice.reducer;
