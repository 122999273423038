import React from "react";
import FeeExcemptionDetails from "../Components/payments/FeeExemptionDetails";
import AppLayout from "../Layouts/AppLayout";

const FeeExemption = () => {
    return (
        <AppLayout>
            <>
                <FeeExcemptionDetails />
            </>
        </AppLayout>
    );
};

export default FeeExemption;
