import AssignStaffRoleDetails from "../Components/staff/AssignStaffRoleDetails";
import AppLayout from "../Layouts/AppLayout";

const AssignStaffRoles = () => {
  return (
    <AppLayout>
      <>
        <AssignStaffRoleDetails />
      </>
    </AppLayout>
  );
};

export default AssignStaffRoles;
