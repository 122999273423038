import React from "react";
import CourseRegDetails from "../Components/program/CourseRegDetails";
import AppLayout from "../Layouts/AppLayout";

const ViewCourseReg = () => {
    return (
        <AppLayout>
            <>
                <CourseRegDetails />
            </>
        </AppLayout>
    );
};

export default ViewCourseReg;
