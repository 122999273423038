import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;
const token = localStorage.getItem("buAdmToken");

export const getToken = () => (token ? token : null);

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: getAuthorizationHeader(),
    Accept: "application/json",
  },
});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     if (token.isNull()) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     console.log("request config", config);
//     return config;
//   },
//   (error) => {
//     // console.log("request error", error);
//     return Promise.reject(error);
//   }
// );
export default axiosInstance;
