import Modal from "react-bootstrap/Modal";

export default function StudentModal({ onHide, show, children, size }: any) {
  return (
    <Modal
      onHide={onHide}
      show={show}
      className="modal-blur"
      dialogClassName={size ? size : "modal-xl"}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header className="border-0 ">
        <i
          className="btn-close shadow-none"
          role="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={onHide}
          aria-hidden="true"
        />
      </Modal.Header>
      {children}
    </Modal>
  );
}
