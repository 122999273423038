import React, { useState } from "react";
import { useForm } from "react-hook-form";

const Check = () => {
  const [result, setResult] = useState();
  const { register, handleSubmit, reset } = useForm();
  return (
    <div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  Name of Admin
                  <div className="page-pretitle">Role</div>
                  <h2 className="page-title">Check & Update Info.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            View basic details of staff and students, reset password for student
            and change staff password
          </p>
          <hr className="mt-1" />
          <div className="row">
            <div className="col-md-4">
              <input
                {...register("id")}
                type="text"
                className={`form-control name py-3 px-3 shadow-none w-100 mx-auto `}
                placeholder="Enter staff id / student mat. number"
                autoComplete="off"
              />
            </div>
            <div className="col-md-4">
              <select
                id="volunteer__project"
                name="volunteer__project"
                className="p-3 form-select benefits__form--input shadow-none"
                defaultValue="DEFAULT"
                required
              >
                <option value="DEFAULT" disabled>
                  --select category--
                </option>
                <option value="">Student</option>
                <option value="">Academic staff</option>
                <option value="">Non-Academic staff</option>
              </select>
            </div>
            <div className="col-md-4">
              <button
                type="submit"
                className="btn btn-primary w-100 mx-auto py-3 px-3"
              >
                Check
              </button>
            </div>
          </div>
        </div>
        <div className="container">{result}</div>
      </div>
    </div>
  );
};

export default Check;
