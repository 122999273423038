import React from "react";
import SearchStud from "../Components/SearchStud";
import AppLayout from "../Layouts/AppLayout";

const SearchStudent = () => {
    return (
        <AppLayout>
            <>
                <SearchStud />
            </>
        </AppLayout>
    );
};

export default SearchStudent;
