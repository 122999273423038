import React from "react";
import LateReg from "../Components/courses/LateReg";
import AppLayout from "../Layouts/AppLayout";

const LateRegistration = () => {
    return (
        <AppLayout>
            <>
                <LateReg />
            </>
        </AppLayout>
    );
};

export default  LateRegistration;
