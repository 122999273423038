import React from "react";
import CourseMapDetails from "../Components/staff/courseMapDetails";
import AppLayout from "../Layouts/AppLayout";

const StaffCourseMap = () => {
  return (
    <AppLayout>
      <>
        <CourseMapDetails />
      </>
    </AppLayout>
  );
};

export default StaffCourseMap;
