import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
}

export interface ViewScoreData {
  session: string;
  courseCode: string;
}

const initialState: Values = {
  loading: false,
  data: null,
};

export const ViewScore = createAsyncThunk(
  "ViewScore",
  async (data: ViewScoreData) => {
    return axiosInstance
      .post("/students/courses/students-by-course", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const ViewScoreSlice = createSlice({
  name: "ViewScore",
  initialState,
  reducers: {
    clearViewScore: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewScore.pending, (state) => {
      state.loading = true;
      state.data = null;
    });
    builder.addCase(ViewScore.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
    builder.addCase(ViewScore.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
    });
  },
});

export const { clearViewScore } = ViewScoreSlice.actions;

export default ViewScoreSlice.reducer;
