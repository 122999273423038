import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Sessions from "../reusable/sessions";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import BtnLoader from "../reusable/btnLoader";
import { LateCourseReg } from "../../state/courses/lateCourseReg";
import { toast, ToastContainer } from "react-toastify";
import StudentModal from "../modals/StudModal";
import { Modal } from "react-bootstrap";

const LateReg = () => {
  const [modal, setModal] = useState<any>();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    lateCourseReg: { loading, data },
  } = useSelector((state: RootState) => state);
  const initialValues = {
    matricNumber: "",
    session: "",
  };
  const SignInSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Matric number is required"),
    session: Yup.string().required("Session is required"),
  });
  //   console.log(data);
  useEffect(() => {
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setModal(data?.data);
        setModalShow(true);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 30000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data]);

  const handleSubmit = (values: any) => {
    // console.log(values);
    dispatch(LateCourseReg(values));
  };

  return (
    <div>
      <ToastContainer />
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Late Course Registration</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            Check if a student has applied for late registration
          </p>
          <hr className="mt-1" />
          <Formik
            initialValues={initialValues}
            validationSchema={SignInSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-4 my-2">
                      <Field
                        type="text"
                        name="matricNumber"
                        id="matricNumber"
                        placeholder="Enter matric"
                        autoComplete="off"
                        className={
                          errors.matricNumber && touched.matricNumber
                            ? "input-error form-control name py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-control name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          Check
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>

          {/* SPECIFIC STUDENT MODAL STARTS */}
          <div className="modal-blur ">
            <StudentModal
              modal={modal}
              show={modalShow}
              onHide={() => setModalShow(false)}
              size={""}
            >
              <Modal.Body className="pt-0">
                <div className="row mt-3">
                  <h5 className="fw-bold">
                    {modal?.firstName} {modal?.lastName} ({modal?.matricNumber})
                  </h5>
                  <hr />
                  <h6 className="fw-bold">Late Registration Records</h6>
                  {modal?.fee.map((item: any, index: any) => (
                    <>
                      <div className="col-md-1 col-xl-1 my-2">
                        <h6 className="">{index + 1}.</h6>
                      </div>{" "}
                      <div className="col-md-4 col-xl-3 my-2">
                        <p className="content--headtext mb-1">Amount</p>
                        <p className="content--subtext">&#8358;{item.amount}</p>
                      </div>
                      <div className="col-md-4 col-xl-3 my-2">
                        <p className="content--headtext mb-1">Session</p>
                        <p className="content--subtext">{item.session}</p>
                      </div>
                    </>
                  ))}
                </div>
              </Modal.Body>
            </StudentModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LateReg;
