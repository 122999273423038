import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
  nonPagloading: boolean;
  nonPagdata: any;
}

const initialState: Values = {
  loading: false,
  data: null,
  nonPagloading: false,
  nonPagdata: null,
};

export const ViewAllStaff = createAsyncThunk(
  "ViewAllStaff",
  async (page: number) => {
    return axiosInstance
      .get(`/staffs?page=${page}&limit=10`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const ViewAllStaffNonPaginated = createAsyncThunk(
  "ViewAllStaffNonPaginated",
  async () => {
    return axiosInstance
      .get(`/staffs?limit=10000`)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const allStaffSlice = createSlice({
  name: "ViewAllStaff",
  initialState,
  reducers: {
    clearAllStaff: (state) => {
      state.loading = false;
      state.data = null;
      state.nonPagloading = false;
      state.nonPagdata = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewAllStaff.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.nonPagloading = false;
      state.nonPagdata = null;
    });
    builder.addCase(ViewAllStaff.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.nonPagloading = false;
      state.nonPagdata = null;
    });
    builder.addCase(ViewAllStaff.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.nonPagloading = false;
      state.nonPagdata = null;
    });
    builder.addCase(ViewAllStaffNonPaginated.pending, (state) => {
      state.loading = false;
      state.data = null;
      state.nonPagloading = true;
      state.nonPagdata = null;
    });
    builder.addCase(
      ViewAllStaffNonPaginated.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.data = null;
        state.nonPagloading = false;
        state.nonPagdata = payload;
      }
    );
    builder.addCase(ViewAllStaffNonPaginated.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = null;
      state.nonPagloading = false;
      state.nonPagdata = payload;
    });
  },
});

// export const { clearStudentData } = studentSlice.actions;

export default allStaffSlice.reducer;
