import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

export interface Values {
  loading: boolean;
  data: any;
  updateLoading: boolean;
  updateData: any;
}

export interface StaffRoleData {
  programme: string;
  session: string;
}

export interface UpdateStaffRoleData {
  session: string;
  programme: string;
  role: number;
  level: number;
  staffId: string;
}

const initialState: Values = {
  loading: false,
  data: null,
  updateLoading: false,
  updateData: null,
};

export const ViewStaffRoles = createAsyncThunk(
  "ViewStaffRoles",
  async (data: StaffRoleData) => {
    return axiosInstance
      .post("/staffs/roles", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const UpdateStaffRoles = createAsyncThunk(
  "UpdateStaffRoles",
  async (data: UpdateStaffRoleData) => {
    return axiosInstance
      .put("/staffs/roles", data)
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

const StaffRoleSlice = createSlice({
  name: "StaffRoleSlice",
  initialState,
  reducers: {
    clearStaffRole: (state) => {
      state.loading = false;
      state.data = null;
      state.updateLoading = false;
      state.updateData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewStaffRoles.pending, (state) => {
      state.loading = true;
      //   state.data = null;
      state.updateLoading = false;
      state.updateData = null;
    });
    builder.addCase(ViewStaffRoles.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.updateLoading = false;
      state.updateData = null;
    });
    builder.addCase(ViewStaffRoles.rejected, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.updateLoading = false;
      state.updateData = null;
    });
    builder.addCase(UpdateStaffRoles.pending, (state) => {
      state.loading = false;
      //   state.data = null;
      state.updateLoading = true;
      state.updateData = null;
    });
    builder.addCase(UpdateStaffRoles.fulfilled, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      state.updateLoading = false;
      state.updateData = payload;
    });
    builder.addCase(UpdateStaffRoles.rejected, (state, { payload }) => {
      state.loading = false;
      //   state.data = null;
      state.updateLoading = false;
      state.updateData = payload;
    });
  },
});

export const { clearStaffRole } = StaffRoleSlice.actions;

export default StaffRoleSlice.reducer;
