const date: Date = new Date();
const currentYear = date.getFullYear();
const limitYear = 2017;
const currentSession = `${currentYear}/${currentYear + 1}`;

const Sessions : string[] = [];

for (let i = limitYear; i <= currentYear; i++) {
  if (!Sessions.includes(currentSession)) {
    Sessions.push(`${i}/${i + 1}`);
  }
}

export default Sessions;