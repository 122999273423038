import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import BtnLoader from "../reusable/btnLoader";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Sessions from "../reusable/sessions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { ViewAllPrograms } from "../../state/programs/programs";
import LinearDeterminate from "../reusable/LinearDeterminate";
import {
  ViewCurricData,
  ViewCurriculum,
} from "../../state/courses/curricullum";

const Curric = () => {
  const [curricData, setCurricData] = useState<[]>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    viewAllProg,
    viewCurric: { loading, data },
  } = useSelector((state: RootState) => state);
  const initialValues: ViewCurricData = {
    programme: "",
    session: "",
  };
  const ValidationSchema = Yup.object().shape({
    programme: Yup.string().required("Programme is required"),
    session: Yup.string().required("Session is required"),
  });

  useEffect(() => {
    if (!viewAllProg?.data) {
      dispatch(ViewAllPrograms());
    }
    if (data) {
      if (data?.name === "AxiosError") {
        toast.error(
          `${
            data?.message
              ? data?.message
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      if (data?.success === true) {
        setCurricData(data?.data);
      }
      if (data?.success === false) {
        toast.error(
          `${
            data?.message
              ? data?.message?.name[0]
              : "Something went wrong please try again"
          }`,
          {
            position: "top-center",
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  }, [data]);

  // console.log(curricData);

  const handleSubmit = (values: ViewCurricData) => {
    dispatch(ViewCurriculum(values));
  };

  return (
    <div>
      <ToastContainer />
      <div className="fixed-top">
        {viewAllProg?.loading && <LinearDeterminate />}
      </div>
      <div className="page-wrapper">
        <div>
          <div className="page-header d-print-none">
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title">Curriculum</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="mb-0">
            View the curriculum of each program based on each session
          </p>
          <hr className="mt-1" />

          <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              const { errors, touched, isValid, dirty } = formik;
              return (
                <Form>
                  <div className="row">
                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="programme"
                        type="programme"
                        //   defaultValue={initialprogramme}
                        className={
                          errors.programme && touched.programme
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select programme ...
                        </option>
                        {viewAllProg?.data?.data?.map(
                          (item: any, index: number) => (
                            <option value={item.programme} key={index}>
                              {item.programmeName}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        name="programme"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      <Field
                        as="select"
                        name="session"
                        type="session"
                        //   defaultValue={initialsession}
                        className={
                          errors.session && touched.session
                            ? "input-error form-select py-3 px-3 shadow-none w-100 mx-auto"
                            : "form-select name py-3 px-3 shadow-none w-100 mx-auto"
                        }
                      >
                        <option value="" disabled>
                          select session ...
                        </option>
                        {Sessions?.map((session, index) => (
                          <option value={session} key={index}>
                            {session}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="session"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="col-md-4 my-2">
                      {loading ? (
                        <BtnLoader />
                      ) : (
                        <button
                          type="submit"
                          className={
                            !(dirty && isValid)
                              ? "disabled-btn btn btn-primary w-100 mx-auto py-3 px-3"
                              : "btn btn-primary w-100 mx-auto py-3 px-3"
                          }
                          disabled={!(dirty && isValid)}
                        >
                          View
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* REQUEST RESPONSE TABLE STARTS */}
        <div>
          {curricData && curricData?.length === 0 ? (
            <div className="container-xl">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <h2 className="page-title py-2">No Results Found</h2>
                </div>
              </div>
            </div>
          ) : (
            curricData && (
              <div className="container my-4">
                <div className="container-xl">
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <h5 className="page-title py-2">Search Results</h5>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-vcenter card-table table-striped">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Course Code</th>
                          <th>Course Title</th>
                          <th>Course Type</th>
                          <th>Unit</th>
                          <th>Programme</th>
                          <th>Session</th>
                          <th>Semester</th>
                          <th>Level</th>
                          {/* <th className="w-1"></th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {curricData &&
                          curricData?.map((item: any, index: number) => (
                            <tr className="" key={item?.id}>
                              <td>{index + 1}</td>
                              <td>{item?.courseCode}</td>
                              <td className="text-muted">
                                {item?.courseTitle}
                              </td>
                              <td className="text-muted">{item?.courseType}</td>
                              <td className="text-muted">{item?.unit}</td>
                              <td className="text-muted">{item?.programme}</td>
                              <td className="text-muted">{item?.session}</td>
                              <td className="text-muted">
                                {item?.semesterName}
                              </td>
                              <td className="text-muted">{item?.level}</td>
                              <td className="text-muted">
                                <button
                                  className={
                                    "btn btn-danger w-100 mx-auto py-1 px-3"
                                  }
                                  //   onClick={() => handleSpecificStudent(item.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {/* REQUEST RESPONSE TABLE ENDS */}
      </div>
    </div>
  );
};

export default Curric;
