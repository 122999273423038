import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../state/store";

const Sidebar = () => {
  const {
    user: { currUserData },
  } = useSelector((state: RootState) => state);
  // console.log(currUserData);

  return (
    <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-menu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <h1 className="navbar-brand navbar-brand-autodark">
          <Link to="/home">
            <img
              src="/assets/logo512.png"
              width="110"
              height="32"
              alt="Tabler"
              className="navbar-brand-image"
            />
          </Link>
        </h1>
        <div className="navbar-nav flex-row d-lg-none">
          <div className="d-none d-lg-flex">
            <a
              href="?theme=dark"
              className="nav-link px-0 hide-theme-dark"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              aria-label="Enable dark mode"
            >
              {/* <!-- Download SVG icon from http://tabler-icons.io/i/moon --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"></path>
              </svg>
            </a>
            <a
              href="?theme=light"
              className="nav-link px-0 hide-theme-light"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              aria-label="Enable light mode"
            >
              {/* <!-- Download SVG icon from http://tabler-icons.io/i/sun --> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <circle cx="12" cy="12" r="4"></circle>
                <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="navbar-menu">
          <ul className="navbar-nav pt-lg-3">
            {/* HOME */}
            <li
              className={`nav-item ${
                window.location.pathname.includes("home") && "active"
              }`}
            >
              <Link className="nav-link active" to="/home">
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/home --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                  </svg>
                </span>
                <span className="nav-link-title">Home</span>
              </Link>
            </li>

            {/* STUDENTS */}
            <li
              className={`nav-item dropdown ${
                window.location.pathname.includes("students") && "active"
              }`}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-base"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                // data-bs-auto-close="false"
                type="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/package --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                    <line x1="12" y1="12" x2="20" y2="7.5"></line>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                    <line x1="12" y1="12" x2="4" y2="7.5"></line>
                    <line x1="16" y1="5.25" x2="8" y2="9.75"></line>
                  </svg>
                </span>
                <span className="nav-link-title">Students</span>
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {/* <li>
                  <Link
                    className="dropdown-item"
                    to="/students/check-update-info"
                  >
                    Check & update info
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className="dropdown-item disable-link"
                    to="/students/register-courses"
                  >
                    Register courses
                  </Link>
                </li> */}
                <li>
                  <Link className="dropdown-item" to="/students/search-student">
                    Search student (by name)
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/students/search-student-by-matric"
                  >
                    Search student (by matric)
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/students/suspend-student-account"
                  >
                    Suspend student account
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item disable-link"
                    to="https://bowenstaff.bowen.edu.ng/dynamo-help/students-performance.php"
                  >
                    Students performance
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item disable-link"
                    to="/students/transfer-student"
                  >
                    Transfer student
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item disable-link"
                    to="/students/view-student-result"
                  >
                    View student result
                  </Link>
                </li>
              </ul>
            </li>

            {/* STAFF */}
            <li
              className={`nav-item dropdown ${
                window.location.pathname.includes("staff") && "active"
              }`}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-extra"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/star --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                  </svg>
                </span>
                <span className="nav-link-title">Staff</span>
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item" to="/staff/add-new-staff">
                    Add new staff
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/staff/all-academic-staff"
                  >
                    All academic staff
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/staff/assign-staff-roles"
                  >
                    Assign staff roles
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/staff/search-staff">
                    Search staff (by name)
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item "
                    to="/staff/staff-course-mapping"
                  >
                    Staff course mapping
                  </Link>
                </li>
              </ul>
            </li>

            {/* COURSES */}
            <li
              className={`nav-item dropdown ${
                window.location.pathname.includes("courses") && "active"
              }`}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-layout"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="true"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/layout-2 --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="4" width="6" height="5" rx="2"></rect>
                    <rect x="4" y="13" width="6" height="7" rx="2"></rect>
                    <rect x="14" y="4" width="6" height="7" rx="2"></rect>
                    <rect x="14" y="15" width="6" height="5" rx="2"></rect>
                  </svg>
                </span>
                <span className="nav-link-title">Courses</span>
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item" to="/courses/add-courses">
                    Add Courses
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item disable-link"
                    to="/courses/course-materials"
                  >
                    Course materials
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className="dropdown-item disable-link"
                    to="/courses/course-registration-prints"
                  >
                    Course reg. prints
                  </Link>
                </li> */}
                <li>
                  <Link className="dropdown-item" to="/courses/curriculum">
                    Curriculum
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/courses/harmonise-course"
                  >
                    Harmonise course
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/courses/late-course-registration"
                  >
                    Late course registration
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/courses/register-courses-multiple"
                  >
                    Register courses (Multiple)
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item "
                    to="/courses/view-course-registration"
                  >
                    View course registration
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/courses/view-score">
                    View score
                  </Link>
                </li>
              </ul>
            </li>

            {/* PROGRAM */}
            <li
              className={`nav-item dropdown ${
                window.location.pathname.includes("program") && "active"
              }`}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-layout"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="true"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/ghost --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7"></path>
                    <line x1="10" y1="10" x2="10.01" y2="10"></line>
                    <line x1="14" y1="10" x2="14.01" y2="10"></line>
                    <path d="M10 14a3.5 3.5 0 0 0 4 0"></path>
                  </svg>
                </span>
                <span className="nav-link-title">Program</span>
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link className="dropdown-item " to="/program/allocate">
                    Allocate
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item "
                    to="/program/view-allocations"
                  >
                    View allocations
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/program/view-all-programs"
                  >
                    View all programs
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/program/view-course-registration"
                  >
                    Registered students (with courses)
                  </Link>
                </li>
              </ul>
            </li>

            {/* PAYMENTS */}
            <li
              className={`nav-item dropdown ${
                window.location.pathname.includes("payments") && "active"
              }`}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-help"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/lifebuoy --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="4"></circle>
                    <circle cx="12" cy="12" r="9"></circle>
                    <line x1="15" y1="15" x2="18.35" y2="18.35"></line>
                    <line x1="9" y1="15" x2="5.65" y2="18.35"></line>
                    <line x1="5.65" y1="5.65" x2="9" y2="9"></line>
                    <line x1="18.35" y1="5.65" x2="15" y2="9"></line>
                  </svg>
                </span>
                <span className="nav-link-title">Payments</span>
              </a>
              <div className="dropdown-menu">
                <Link
                  className="dropdown-item disable-link"
                  to="/payments/debtors"
                >
                  Debtors
                </Link>
                <Link className="dropdown-item" to="/payments/fee-exemption">
                  Fee exemption
                </Link>
                <Link
                  className="dropdown-item disable-link"
                  to="/payments/fee-exemption-freshmen"
                >
                  Fee exemption (freshmen)
                </Link>
              </div>
            </li>

            {/* UTILITY */}
            <li
              className={`nav-item dropdown ${
                window.location.pathname.includes("utility") && "active"
              }`}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#navbar-help"
                data-bs-toggle="dropdown"
                data-bs-auto-close="false"
                role="button"
                aria-expanded="false"
              >
                <span className="nav-link-icon d-md-none d-lg-inline-block">
                  {/* <!-- Download SVG icon from http://tabler-icons.io/i/lifebuoy --> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="4"></circle>
                    <circle cx="12" cy="12" r="9"></circle>
                    <line x1="15" y1="15" x2="18.35" y2="18.35"></line>
                    <line x1="9" y1="15" x2="5.65" y2="18.35"></line>
                    <line x1="5.65" y1="5.65" x2="9" y2="9"></line>
                    <line x1="18.35" y1="5.65" x2="15" y2="9"></line>
                  </svg>
                </span>
                <span className="nav-link-title">Utility</span>
              </a>
              <div className="dropdown-menu">
                <Link className="dropdown-item disable-link" to="/utility/util">
                  util/fix buggy course reg.
                </Link>
                {currUserData?.data?.userType === "super-admin" && (
                  <Link
                    className="dropdown-item "
                    to="/utility/create-new-user"
                  >
                    Create new user
                  </Link>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
