import React from "react";
import CourseMateriaDetails from "../Components/courses/CourseMaterialDetails";
// import Sidebar from "../Components/Sidebar";
import AppLayout from "../Layouts/AppLayout";

const CourseMaterials = () => {
  return (
    <AppLayout>
      <>
        {/* <Sidebar /> */}
        <CourseMateriaDetails />
      </>
    </AppLayout>
  );
};

export default CourseMaterials;
